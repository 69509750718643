import { Fragment } from 'react'

import columbiaUniversity from 'assets/img/research/professors/columbia-university.jpg'
import abigail from 'assets/img/research/professors/abigail-greenleaf.jpeg'
import susan from 'assets/img/research/professors/susan-mcgregor.jpg'
import alexander from 'assets/img/research/professors/alexander-urban.jpg'
import aliHirsa from 'assets/img/research/professors/ali-hirsa.png'
import xuan from 'assets/img/research/professors/xuan-di.jpg'
import hongseok from 'assets/img/research/professors/hongseok-namkoong.jpg'
import dimitri from 'assets/img/research/professors/dimitri-livitz.jpg'
import gustavo from 'assets/img/research/professors/gustavo-novoa.jpg'
import maryum from 'assets/img/research/professors/maryum-sayeed.jpg'

export const professors = {
  title: 'Mentors',
  professors: [
    {
      id: 'columbia-university',
      name: 'Columbia University Data Science Research Institute',
      desc: (
        <Fragment>
          <p>
            The Coding School is honored to collaborate with Columbia
            University professors and faculty to provide students with the
            highest quality research experience available to high school
            students.
          </p>
        </Fragment>
      ),
      img: {
        src: columbiaUniversity,
        alt: 'Columbia University Data Science Research Institute',
      },
    },
    {
      id: 'abigail-greenleaf',
      name: 'Abigail Greenleaf',
      desc: (
        <Fragment>
          <p>
            Dr. Abigail (Abba) Greenleaf, Assistant Professor at Columbia University, is a public health demographer whose
            research focuses on collecting data in low- and middle-income
            countries where using cell phones to survey populations is an
            increasingly viable methodology. In the United States, phone-based
            surveys have been common since the 1980s. In areas such as Africa,
            until recently there was not sufficient cell phone ownership to
            create valid phone-based health estimates, and researchers like Dr.
            Greenleaf have been assessing the reliability of this increasingly
            popular approach to data collection.
          </p>
          <p>
            Dr. Greenleaf currently works with ICAP’s Population-Based HIV
            Impact Assessment (PHIA) project. Carried out under the leadership
            of national ministries of health, PHIA data benchmark a country’s
            progress towards controlling the HIV epidemic. Dr. Greenleaf enjoys
            this rigorous research because it is efficient, cost-effective and
            produces high-quality data that can inform targeted policies and
            programs. As COVID-19 epidemic restraints slowed progress with the
            PHIA project in several countries, Dr. Greenleaf became part of a
            team that quickly catalyzed PHIA data and participants in Lesotho to
            begin a phone-based surveillance system for coronavirus-like
            symptoms. This real-time data creates weekly estimated infection
            levels for the national ministry of health.
          </p>
        </Fragment>
      ),
      img: {
        src: abigail,
        alt: 'Abigail Greenleaf',
      },
    },
    {
      id: 'alexander-urban',
      name: 'Alexander Urban',
      desc: (
        <Fragment>
          <p>
            Alex Urban is an Assistant Professor of Chemical Engineering and a
            core faculty-member of the Columbia Electrochemical Energy Center
            (CEEC) and the Columbia Center for Computational Electrochemistry
            (CCCE).
          </p>

          <p>
            His research interests are in understanding and discovering
            materials and processes for clean-energy applications using
            atomic-scale modeling and data science. Prior to joining the
            Department of Chemical Engineering at Columbia University in 2019,
            Alex was an independent University Fellow in the School of Chemistry
            at the University of St Andrews (UK). Alex obtained his PhD in 2012
            from FAU Erlangen-Nuremberg in Germany and conducted postdoctoral
            research at MIT and UC Berkeley. In 2019, Alex was named a Scialog
            Fellow for Advanced Energy Storage by the Research Corporation for
            Science Advancement.
          </p>
        </Fragment>
      ),
      img: {
        src: alexander,
        alt: 'Alexander Urban',
      },
    },
    {
      id: 'ali-hirsa',
      name: 'Ali Hirsa',
      desc: (
        <Fragment>
          <p>
            Dr. Ali Hirsa is a professor at Columbia University. His research interests are algorithmic trading,
            machine learning, data mining, computational/quantitative finance
            and optimization. His focus has been on developing learning
            algorithms on signal extraction from data.
          </p>
          <p>
            Ali is author of “Computational Methods in Finance”, Chapman &
            Hall/CRC 2012 and co-author of “An Introduction to Mathematics of
            Financial Derivatives”, third edition, Academic Press and is the
            editor of Journal of Investment Strategies. He has several
            publications and is a frequent speaker at academic and practitioner
            conferences.
          </p>
          <p>
            Ali is co-inventor of “Methods for Post Trade Allocation” (US Patent
            8,799,146). The method focuses on allocation of filled orders
            (post-trade) on any security to multiple managed accounts which has
            to be fair and unbiased. Current existing methods lead to biases and
            the invention provides a solution to this problem.
          </p>
        </Fragment>
      ),
      img: {
        src: aliHirsa,
        alt: 'Ali Hirsa',
      },
    },
    {
      id: 'susan-mcgregor',
      name: 'Susan E. McGregor',
      desc: (
        <Fragment>
          <p>
            Susan E. McGregor is an associate research scholar at Columbia
            University's Data Science Institute, where she co-chairs the Center
            for Data, Media & Society.
          </p>
          <p>
            McGregor's research primarily centers on security and privacy issues
            affecting journalists and media organizations. Her current work
            includes an NSF-funded project to provide readers with stronger
            guarantees about digital media by incorporating cryptographic
            signatures into digital publishing workflows. She works with natural
            language processing experts to develop novel classifiers for
            detecting abusive speech on Twitter, and is researching effective
            models for peer support with a focus on the media space. Her work in
            these and related areas has received support from the NSF, the
            Knight Foundation, Google, Columbia University, among others.
          </p>
          <p>
            As an educator, McGregor is committed to increasing the reach of
            data science education to help ensure that essential literacies
            about data-driven systems are accessible to learners of every age
            and background. Prior to her work at Columbia University, McGregor
            was the senior programmer for the News Graphics team at the Wall
            Street Journal (WSJ), a front-end programmer at the photo wire
            service MediaVast (acquired by Getty Images), and a reporter for The
            New York Amsterdam News.
          </p>
        </Fragment>
      ),
      img: {
        src: susan,
        alt: 'Susan E. McGregor',
      },
    },
    {
      id: 'xuan-di',
      name: 'Xuan (Sharon) Di',
      desc: (
        <Fragment>
          <p>
            Dr. Xuan (Sharon) Di is an Associate Professor in the Department of
            Civil Engineering and Engineering Mechanics at Columbia University
            and serves on a committee for the Smart Cities Center in the Data
            Science Institute.
          </p>
          <p>
            Prior to joining Columbia, she was a Postdoctoral Research Fellow at
            the University of Michigan Transportation Research Institute. She
            received her Ph.D. degree from the Department of Civil,
            Environmental, and Geo-Engineering at the University of Minnesota,
            Twin Cities in 2014. Dr. Di directs the DitecT (Data and innovative
            technology-driven Transportation) Lab @ Columbia University. Her
            research lies at the intersection of game theory, dynamic control,
            and machine learning. She is specialized in emerging transportation
            systems optimization and control, shared mobility modeling, and
            data-driven urban mobility analysis. Details about DitecT Lab and
            Prof. Sharon Di's research can be found in the following link:{' '}
            <a
              href="http://sharondi-columbia.wixsite.com/ditectlab"
              rel="noreferrer"
            >
              http://sharondi-columbia.wixsite.com/ditectlab
            </a>
            .
          </p>
        </Fragment>
      ),
      img: {
        src: xuan,
        alt: 'Xuan (Sharon) Di',
      },
    },
    {
      id: 'hongseok-namkoong',
      name: 'Hongseok Namkoong',
      desc: (
        <Fragment>
          <p>
            Hongseok Namkoong is an Assistant Professor in the Decision, Risk,
            and Operations division at Columbia Business School and a member of
            the Columbia Data Science Institute.
          </p>
          <p>
            His research interests lie at the interface of machine learning,
            operations research, and causal inference, with a particular
            emphasis on developing reliable learning methods for decision-making
            problems. He received his Ph.D. from Stanford University and worked
            as a research scientist at Facebook Core Data Science before joining
            Columbia. Hong is a recipient of several awards and fellowships,
            including best paper awards at the Neural Information Processing
            Systems conference and the International Conference on Machine
            Learning, and the INFORMS Applied Probability Society.
          </p>
        </Fragment>
      ),
      img: {
        src: hongseok,
        alt: 'Hongseok Namkoong',
      },
    },
    {
      id: 'dimitri-livitz',
      name: 'Dimitri Livitz',
      desc: (
        <Fragment>
          <p>
            Dimitri is a chemical engineer, who approaches research
            computationally. He has worked in bioinformatics, studying the
            dynamics of cancer growth and now studies soft matter, focusing on
            microrobots.
          </p>
          <p>
            He is interested in exploring the intersection of data science and
            physical systems, and uses python and julia for his research. He is
            also excited about data visualization, and trying to present
            findings in interesting ways.
          </p>
        </Fragment>
      ),
      img: {
        src: dimitri,
        alt: 'Dimitri Livitz',
      },
    },
    // {
    //   id: 'gustavo-novoa',
    //   name: 'Gustavo Novoa',
    //   desc: (
    //     <Fragment>
    //       <p>
    //         Gustavo Novoa is a PhD candidate in Political Science with a minor
    //         in quantitative methods at Columbia University.
    //       </p>
    //       <p>
    //         He is currently writing a mixed-methods dissertation on local
    //         gerrymandering in U.S. cities using Bayesian simulations and expert
    //         interviews to determine how local gerrymandering is different from
    //         what we see at the federal level. In his free time he likes
    //         climbing, playing guitar, and gardening.
    //       </p>
    //     </Fragment>
    //   ),
    //   img: {
    //     src: gustavo,
    //     alt: 'Gustavo Novoa',
    //   },
    // },
    // {
    //   id: 'maryum-sayeed',
    //   name: 'Maryum Sayeed',
    //   desc: (
    //     <Fragment>
    //       <p>
    //         Maryum Sayeed is a PhD student in the Department of Astronomy at
    //         Columbia University. She received her Bachelor's in Physics &
    //         Astronomy from the University of British Columbia in Vancouver,
    //         Canada.
    //       </p>
    //       <p>
    //         While she has a diverse research background, her main focus is in
    //         stellar astrophysics, galactic archaeology and the
    //         stellar-exoplanetary synergy. She is interested in using large-scale
    //         photometric, spectroscopic, and astrometric surveys - such as
    //         Kepler, TESS, Gaia, APOGEE, LAMOST and GALAH - to study the
    //         formation and evolution of the Milky Way. She is also passionate
    //         about mentorship and outreach.
    //       </p>
    //     </Fragment>
    //   ),
    //   img: {
    //     src: maryum,
    //     alt: 'Maryum Sayeed',
    //   },
    // },
  ],
}
