import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import map from "assets/img/home/dot-map.svg";

import styles from "./styles.module.scss";

export const StudentMapCounter = () => {
  return (
    <div className={styles.StudentMapCounter}>
      <div className="container">
        <div
          className={styles.visualText}
          data-aos="fade-up"
          data-aos-duration="500"
        >
          <h3 className="mb-0 text-center">
            We're<span> inspiring</span> the next generation of{" "}
            <span className="text-blue-gold-gradient d-block">STEM leaders</span>
          </h3>
        </div>
      </div>

      <div className={styles.dotMap + " mt-50"}>
        <img src={map} alt="Map" />
        <div className={styles.imageBoxWrap + " container"}>
          <div className="row justify-content-center">
            <div
              className="col-md-4"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className={styles.imageBox}>
                <h4>
                  <VisibilitySensor>
                    {({ isVisible }) => (
                      <div style={{ height: 80 }}>
                        {isVisible ? (
                          <CountUp end={50000} duration={2.2} />
                        ) : null}
                      </div>
                    )}
                  </VisibilitySensor>
                </h4>
                <h6>Students</h6>
              </div>
            </div>
            {/* <div
              className="col-md-4"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className={styles.imageBox}>
                <h4>
                  <VisibilitySensor>
                    {({ isVisible }) => (
                      <div style={{ height: 80 }}>
                        {isVisible ? <CountUp end={130} /> : null}
                      </div>
                    )}
                  </VisibilitySensor>
                </h4>
                <h6>Countries</h6>
              </div>
            </div> */}
            <div
              className="col-md-4"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className={styles.imageBox}>
                <h4>
                  <VisibilitySensor>
                    {({ isVisible }) => (
                      <div style={{ height: 80 }}>
                        {isVisible ? <CountUp end={50} /> : null}
                      </div>
                    )}
                  </VisibilitySensor>
                </h4>
                <h6>States</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
