import { Fragment } from 'react'
import img from "assets/img/research/ds-research-program-overview.jpg";

import qc from 'assets/img/research/quantum-computing.png'
import ds from 'assets/img/research/data-science.png'

export const programOverview = {
  title: (
    <Fragment>
      Program{' '}
      <span className="text-animated">
        <span>Overview</span>
      </span>
    </Fragment>
  ),
  subTitle:
    'Increase college competitiveness by being among the first high school students in the world to conduct research in Quantum Computing and Big Data.',
  desc: (
    <Fragment>
      The National High School Research Program offers unprecedented
      opportunities for students to develop tangible technical skills in some of
      the world's most cutting-edge tech fields - Quantum Computing and Big Data
      - which have applications across every industry from medicine to finance,
      defense, entertainment, and more.
      <br />
      <br />
      Over five weeks, students will transform from having zero knowledge to a
      full skill set of technical skills and will receive training and
      mentorships in conducting research. Working closely with local and
      national mentors from government, industry, or academia, students will
      develop their own research projects using real-world datasets and tech
      applications, culminating in projects that can be showcased in their
      college applications and presented at the TCS Summer Research Symposium.
    </Fragment>
  ),
  img: {
    src: img,
    alt: 'Program Overview',
  },
  fullWidthContent: (
    <Fragment>
      <div className="mt-20 bg-blue-gradient rounded p-15">
        <p className="text-center text-white fs-20">
          Students may apply to conduct research in
        </p>
        <div className="row justify-content-center">
          <div className="col-12 col-md-4 text-center mb-30 mb-md-0">
            <img
              className="invert-img"
              src={qc}
              alt="Quantum Computing"
              style={{ height: 50 }}
            />
            <p className="text-white mb-0 mt-15 fs-18">Quantum Computing</p>
          </div>
          <div className="col-12 col-md-4 text-center">
            <img
              className="invert-img"
              src={ds}
              alt="Data Science"
              style={{ height: 50 }}
            />
            <p className="text-white mb-0 mt-15 fs-18">Data Science</p>
          </div>
        </div>
      </div>
    </Fragment>
  ),
}
