import { Fragment } from 'react'
import bg from 'assets/img/research/university-columbia.jpg'

export const banner = {
  bg: bg,
  heading: (
    <Fragment>
      National High School Research Program <i>in</i>
      <span className="d-block text-gold">AI and Quantum Computing</span>
    </Fragment>
  ),
  subHeading:
    'Develop real-world research skills | Conduct research with university faculty | Increase college competitiveness |',
  description: (
    <Fragment>
      {/* <span span className="d-block text-gold mb-5">Virtual & Hybrid Opportunities</span> */}
      <span span className="d-block mb-5">Incoming 10<sup>th</sup> - 12<sup>th</sup> + Recent High School Graduates</span>
      <span span className="d-block">June 23 - July 25, 2025</span>
    </Fragment>
  ),
  links: [
    {
      linkText: 'Apply',
      link: 'https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY',
      isExternal: true,
      class: 'btn gold-gradient px-30'
    },
  ],
}
