import { Fragment } from "react";

export const techRequirements = {
  title: (
    <Fragment>
      Tech {" "}
      <span className="text-animated">
        <span>Requirements</span>
      </span>
    </Fragment>
  ),
  descriptions: [
    "Summer programs are instructed live in English virtually during the EST time zone over the dates listed. Students will require access to reliable broadband on a device that is audio and video capable, ideally from a quiet and distraction-free environment. Devices may include computers, laptops, notebooks, and Chromebooks. Students will need to access Zoom and Google Co-lab from their devices to participate fully in the course. These are all free platforms and instructions will be provided.",
  ],
};
