import classNames from 'classnames'
import styles from './styles.module.scss'
import google from 'assets/img/common/google.png'

import unc from "assets/img/common/unc-chapel-hill.png";
import umbc from "assets/img/common/umbc.png";
import uci from "assets/img/common/uci.png";
import comines from "assets/img/common/comines.png";
import umiami from "assets/img/common/university-of-miami.png";

export const Professors = props => {
  const { data, addGoogleCard, googleCardText, addAdditionalPartners } = props

  return (
    <div className={styles.professors}>
      <div className="container">
        <h4 className={styles.title}>{data?.title}</h4>
        <div className={styles.tabs}>
          <div
            className={classNames(styles.tabPills, 'nav')}
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            {data?.professors?.map((item, idx) => (
              <>
                <button
                  className={classNames(styles.pill, idx === 0 ? 'active' : '')}
                  id={`v-pills-${item?.id}-tab`}
                  data-toggle="pill"
                  data-target={`#v-pills-${item?.id}`}
                  type="button"
                  role="tab"
                  aria-controls={`v-pills-${item?.id}`}
                  aria-selected={idx === 0 ? 'true' : 'false'}
                >
                  <span className={styles.tabTitle}>
                    <img
                      src={item?.img?.src}
                      alt={item?.img?.alt}
                      className={styles.avatar}
                    />
                    <span className={styles.name}>{item?.name}</span>
                  </span>
                </button>
                {idx === 0 ? (
                  <p className="mt-10 mb-0">
                    Some mentors from previous programs
                  </p>
                ) : null}
              </>
            ))}
          </div>
          <div
            className={classNames(styles.tabContent, 'tab-content')}
            id="v-pills-tabContent"
          >
            {data?.professors?.map((item, idx) => (
              <div
                className={classNames(
                  'tab-pane fade',
                  idx === 0 ? 'show active' : ''
                )}
                id={`v-pills-${item?.id}`}
                role="tabpanel"
                aria-labelledby={`v-pills-${item?.id}-tab`}
              >
                <div className={styles.tabPane}>
                  <div className={styles.top}>
                    <div className="img-highlighted left">
                      <img
                        src={item?.img?.src}
                        alt={item?.img?.alt}
                        className={styles.avatar}
                      />
                    </div>
                    <h5 className={styles.name}>{item?.name}</h5>
                  </div>
                  <div className={styles.desc}>{item?.desc}</div>
                </div>
                {addGoogleCard ? (
                  <div className={classNames(styles.tabPane, 'mt-20')}>
                    <div className={styles.top}>
                      <img
                        src={google}
                        alt="Google"
                        className={styles.avatar}
                      />
                      <h5 className={styles.name}>
                        Google Industry Professionals
                      </h5>
                    </div>
                    <div className={styles.desc}>
                      <p>
                        {googleCardText}
                      </p>
                    </div>
                  </div>
                ) : null}
                {/* {addAdditionalPartners ? (
                  <div className={classNames(styles.tabPane, 'mt-20 py-15')}>
                    <p className='text-center'>Some of our additional research mentors include</p>
                    <ul className={styles.partners}>
                      <li><img src={unc} alt="UNC Chapel Hill" /></li>
                      <li><img src={umbc} alt="University of Maryland Baltimore County" /></li>
                      <li><img src={uci} alt="UC Irvine" /></li>
                      <li><img src={comines} alt="Colorado School of Mines" /></li>
                      <li><img src={umiami} alt="University of Miami" /></li>
                    </ul>
                  </div>
                ) : null} */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
