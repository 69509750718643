import { EmergingTech, Research, Scholar, University } from "component/Common/Icons";

export const offerings = [
  {
    svg: <EmergingTech color="#fff" />,
    title: "Deep dive into emerging tech skills",
  },
  {
    svg: <Research />,
    title: "Participate in research projects and report your findings",
  },
  {
    svg: <Scholar />,
    title: "Receive mentorship from faculty, PhDs, & industry experts",
  },
  {
    svg: <University />,
    title: "Increase college competitiveness",
  },
];
