import unc from "assets/img/common/unc-chapel-hill.png";
import columbiaUni from "assets/img/common/columbia-university.jpg";
import uarkansas from "assets/img/common/university-of-arkansas.png";
import uflorida from "assets/img/common/university-of-florida.png";
import umbc from "assets/img/common/umbc.png";
import uci from "assets/img/common/uci.png";
import comines from "assets/img/common/comines.png";
import umiami from "assets/img/common/university-of-miami.png";
import ncstate from "assets/img/common/nc-state-university.png";

export const createdBy = {
  tagline: "Some of our Research Partners include",
  logos: [
    { src: unc, alt: "UNC Chapel Hill" },
    { src: columbiaUni, alt: "columbia University" },
    // { src: uarkansas, alt: "University of Arkansas" },
    // { src: uflorida, alt: "University of Florida" },
    { src: umbc, alt: "University of Maryland Baltimore County" },
    { src: uci, alt: "UC Irvine" },
    { src: comines, alt: "Colorado School of Mines" },
    { src: umiami, alt: "University of Miami" },
    // { src: ncstate, alt: "NC State University" },
  ],
};