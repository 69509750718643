import { Fragment } from "react";

import bg from "assets/img/courses/ai-bg.jpg";
import berkely from "assets/img/common/uc-berkeley.png";
import mit from "assets/img/common/mit.png";
import stanford from "assets/img/common/stanford-horizontal.png";
import jhu from "assets/img/common/jhu.svg";

export const banner = {
  bannerBg: bg,
  heading: "Take the #1 courses for high school students in",
  programs: "Artificial Intelligence | Quantum Computing",
  courseCreatedBy: {
    para: "Created by researchers at",
    logos: [
      { src: berkely, alt: "Berkely" },
      { src: mit, alt: "MIT" },
      { src: stanford, alt: "Stanford" },
      { src: jhu, alt: "John Hopkins University" },
    ],
  },
  programsTypes: "For High School Students, University, & Workforce",
  // programsFor: <Fragment>2025-26 course registration opening March 2025 </Fragment>,
  bannerLinks: [
    {
      linkText: (
        <Fragment>
          <span>2025-26 Interest Form</span>
        </Fragment>
      ),
      link: "https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY",
      isExternal: true,
    },
    // {
    //   linkText: (
    //     <Fragment>
    //       <span>
    //         Introduction to AI
    //         <span>Apply Now</span>
    //       </span>
    //     </Fragment>
    //   ),
    //   link: "/train-course-application",
    //   isExternal: false,
    // },
  ],
};
