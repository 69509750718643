import background from 'assets/img/research/logistics-bg.jpg'
import { Fragment } from 'react'

export const logistics = {
  background: background,
  title: 'Logistics',
  toggleData: [
    {
      sectionTitle: 'Data Science',
      logisticsList: [
        {
          subTitle: 'Grades',
          desc: 'Incoming 9th-12th graders + college freshmen',
        },
        {
          subTitle: 'Dates',
          desc: 'July 7 - Aug 8, 2025',
        },
        {
          subTitle: 'Time',
          desc: (
            <Fragment>
              Monday - Friday
              <span className="d-block">12 - 4pm ET (9am - 1pm PT)</span>
              <small className="d-block">
                *Must be able to join live sessions
              </small>
            </Fragment>
          ),
        },
        {
          subTitle: 'Location',
          desc: (
            <Fragment>
              <span className="d-block mb-5">
                Virtual, with live instruction
              </span>
              <small className="d-block">
                *Special events such as campus or facility tours, community
                events, and speaker panels may periodically take place virtually
                or in-person depending on a student’s regional hub.
              </small>
            </Fragment>
          ),
        },
        {
          subTitle: 'Prerequisites',
          desc: (
            <Fragment>
              <span className="d-block mb-5">
                No prior STEM experience required; students will learn all
                skills required during the program.
              </span>
            </Fragment>
          ),
        },
        {
          subTitle: 'Tuition',
          desc: (
            <Fragment>
              <span className="d-block mb-5">$3995</span>
              <span className="d-block mb-5">
                <strong className="text-gold">100 Full Scholarships available</strong> <br /> (see below)</span>
            </Fragment>
          ),
        },
      ],
      extraInfo: [
        {
          title: <Fragment>Become a <span className='text-gold'>DSRP Scholar</span> this Summer</Fragment>,
          desc: [
            <span className='text-gold text-bold fs-16'>100 Full Scholarships will be awarded!</span>,
            <Fragment><span className="mb-10 d-block">Plus a $750 stipend given to students for program completion</span></Fragment>,
            <Fragment>Eligibility: Resident of <strong> New York, Washington D.C., Maryland, Virginia, Southern California</strong>, and <strong>rural areas across the U.S.</strong></Fragment>,
            <Fragment><small>*Military-connected students are encouraged to apply.<br />*The DSRP Scholars Program is made possible thanks to the U.S. Department of Defense (DoD) and the Defense STEM Education Consortium (DSEC).</small></Fragment>,
          ],
        },
      ],
    },
    {
      sectionTitle: 'Quantum Computing',
      logisticsList: [
        {
          subTitle: 'Grades',
          desc: 'Incoming 10th-12th graders + college freshmen',
        },
        {
          subTitle: 'Dates',
          desc: 'June 23 - July 25, 2025',
        },
        {
          subTitle: 'Time',
          desc: (
            <Fragment>
              Monday - Friday
              <span className="d-block">12 - 4pm ET (9am - 1pm PT)</span>
              <small className="d-block">
                *Must be able to join live sessions
              </small>
            </Fragment>
          ),
        },
        {
          subTitle: 'Location',
          desc: (
            <Fragment>
              <span className="d-block mb-5">
                Virtual, with live instruction
              </span>
              <small className="d-block">
                *Special events such as campus or facility tours, community
                events, and speaker panels may periodically take place virtually
                or in-person depending on a student’s regional hub.
              </small>
            </Fragment>
          ),
        },
        {
          subTitle: 'Prerequisites',
          desc: (
            <Fragment>
              <span className="d-block mb-5">
                TCS’ Introduction to Quantum Computing
              </span>
              <span className="d-block mb-5">-or-</span>
              <span className="d-block mb-">Pre-program Self-Study course</span>
            </Fragment>
          ),
        },
        {
          subTitle: 'Tuition',
          desc: (
            <Fragment>
              <span className="d-block mb-5">5-week program: $3995</span>
              <span className="d-block mb-5">
                Self-study component: $1000
                <small className='d-block'>(only for non-alum students)</small>
              </span>
              <span className="d-block text-gold mb-5">
                Full scholarships for students in Maryland
              </span>
              (see below)
            </Fragment>
          ),
        },
      ],
      extraInfo: [
        {
          title: <Fragment>Become a <span className='text-gold'>Equitech Scholar</span> this Summer</Fragment>,
          desc: [
            <span className='text-gold text-bold fs-16'>Full Scholarships for Maryland students!</span>,
            <Fragment><span className="my-10 d-block">Thanks to Tedco’s Equitech Growth Fund Award, Maryland students can become Equitech Scholars this summer and receive full scholarships for all components of the Quantum National High School Research Program. Awarded students may also have opportunities for in-person research experiences, lab tours and meetings with leading experts in the field. No prior experience in STEM required.</span></Fragment>,
            <Fragment><small>Need-based scholarships are available for students in other states.</small></Fragment>
          ],
        },
      ],
    },
    {
      sectionTitle: 'Artificial Intelligence',
      logisticsList: [
        {
          subTitle: 'Grades',
          desc: 'Incoming 10th-12th graders + college freshmen',
        },
        {
          subTitle: 'Dates',
          desc: 'June 23 - July 25, 2025',
        },
        {
          subTitle: 'Time',
          desc: (
            <Fragment>
              Monday - Friday
              <span className="d-block">12 - 4pm ET (9am - 1pm PT)</span>
              <small className="d-block">
                *Must be able to join live sessions
              </small>
            </Fragment>
          ),
        },
        {
          subTitle: 'Location',
          desc: (
            <Fragment>
              <span className="d-block mb-5">
                Virtual, with live instruction
              </span>
              <small className="d-block">
                *Special events such as campus or facility tours, community
                events, and speaker panels may periodically take place virtually
                or in-person depending on a student’s regional hub.
              </small>
            </Fragment>
          ),
        },
        {
          subTitle: 'Prerequisites',
          desc: (
            <Fragment>
              <span className="d-block mb-5">
                TCS’ Introduction to Artificial Intelligence
              </span>
              <span className="d-block mb-5">-or-</span>
              <span className="d-block mb-5">
                Pre-program Self-Study course
              </span>
              <span className="d-block mb-5">-and-</span>
              <span className="d-block">
                basic coding skills
                <small className='d-block'>(ie. variables, conditionals, functions, etc)</small>
              </span>
            </Fragment>
          ),
        },
        {
          subTitle: 'Tuition',
          desc: (
            <Fragment>
              <span className="d-block mb-5">5-week program: $3995</span>
              <span className="d-block mb-5">
                Self-study component: $1000
                <small className='d-block'>(only for non-alum students)</small>
              </span>
              <small className="d-block">
                *Scholarships available for students with financial need.
              </small>
            </Fragment>
          ),
        },
      ],
    },
  ],
}
