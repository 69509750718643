import classNames from 'classnames'
import styles from './styles.module.scss'

export const Logistics = props => {
  const { data, withToggle, hasBgOverlay } = props
  return (
    <section
      className={styles.logistics}
      style={{ backgroundImage: 'url(' + data?.background + ')' }}
    >
      {hasBgOverlay && <div className={styles.overlay}></div>}
      <div className="container">
        <h4 className={styles.title}>{data?.title}</h4>
        {withToggle ? (
          <div>
            <div
              className={classNames(styles.logisticsTabs, 'nav mb-30')}
              role="tablist"
            >
              {data?.toggleData?.map((item, idx) => (
                <a
                  id={`${idx}-tab`}
                  data-toggle="tab"
                  href={`#logistics-${idx}`}
                  role="tab"
                  aria-controls={`${idx}`}
                  aria-selected="false"
                  className={styles.tabTitle}
                  key={idx}
                >
                  <span>{item?.sectionTitle}</span>
                </a>
              ))}
            </div>
            <div className="tab-content mb-50" id="logisticsTabContent">
              {data?.toggleData?.map((item, idx) => (
                <div
                  className={'tab-pane fade'}
                  id={`logistics-${idx}`}
                  role="tabpanel"
                  aria-labelledby={`${Object.keys(item)}-tab`}
                  key={idx}
                >
                  <h3 className="text-white text-center mt-20 mb-30">
                    {item?.sectionTitle}
                  </h3>
                  <ul
                    className={classNames(
                      styles.logisticsList,
                      'list-unstyled mb-0'
                    )}
                  >
                    {item?.logisticsList?.map((item, idx) => (
                      <li key={idx}>
                        <span className={styles.subTitle}>
                          {item?.subTitle}
                        </span>
                        <span className={styles.desc}>{item?.desc}</span>
                      </li>
                    ))}
                  </ul>
                  {item?.extraInfo ? (
                    <div className={styles.extraInfoWrap}>
                      {item?.extraInfo?.map((info, infoIdx) => (
                        <div className={styles.extraInfo} key={infoIdx}>
                          <h5>{info?.title}</h5>
                          {info?.desc
                            ? info?.desc?.map((itm, inIdx) => (
                              <p key={inIdx}>{itm}</p>
                            ))
                            : null}
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <ul
            className={classNames(styles.logisticsList, 'list-unstyled mb-0')}
          >
            {data?.logisticsList?.map((item, idx) => (
              <li key={idx}>
                <span className={styles.subTitle}>{item?.subTitle}</span>
                <span className={styles.desc}>{item?.desc}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </section>
  )
}
