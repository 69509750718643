import CountUp from "react-countup";
import engBg from "assets/img/home/eng-bg-old-1.png";
import hand from "assets/img/home/eng-bg-hand.png";

import styles from "./styles.module.scss";

export const LearningModel = () => {
  return (
    <div
      className={styles.engBg}
      style={{ backgroundImage: "url(" + engBg + ")" }}
    >
      <div className={styles.containerBig + " container"}>
        <div className="row align-items-center">
          <div
            className="col-lg-6 col-md-12"
            data-aos="fade-left"
            data-aos-duration="500"
          >
            <h3>
              We&apos;ve Created a Learning Model That&apos;s <u>Making Real Impact.</u>
            </h3>
            <p className="mb-10">
              To expand the pool of qualified talent in
              tech, every student needs access to high quality computer science
              education. We&apos;re making this happen. Taught by PhD researchers
              and professional software engineers, our students gain in-depth
              knowledge and real-world skills in emerging technologies, such as
              AI and Quantum Computing, that they would not otherwise have
              access to.
            </p>
            <p className="mb-10">
              Our model offers virtual courses with live instruction and support, teaching
              nearly 10,000 students in a single course with staggeringly
              impressive outcomes.
            </p>
            <p className="text-gold text-bold">
              Through our model, every student in the U.S. can have access to
              high-quality computer science instruction.
            </p>
          </div>
          <div
            className="col-lg-6 col-md-12"
            data-aos="fade-right"
            data-aos-duration="500"
          >
            <img className="img-responsive" src={hand} alt="Hand" />
          </div>
        </div>
        <div className={styles.engBgBottom}>
          <div className="row align-items-center mt-50">
            <div
              className="col-6 col-lg-3 text-center mb-30 mb-lg-0"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <h5>
                <CountUp end={99} duration={3.75} />%
              </h5>
              <p>
                of students are more likely to take a STEM class in college
                after our courses
              </p>
            </div>
            <div
              className="col-6 col-lg-3 text-center mb-30 mb-lg-0"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <h5>
                <CountUp end={98} duration={3.75} />%
              </h5>
              <p>of students would recommend our courses to their friends</p>
            </div>
            <div
              className="col-6 col-lg-3 text-center mb-30 mb-lg-0"
              data-aos="fade-up"
              data-aos-duration="4000"
            >
              <h5>
                <CountUp end={95} duration={3.75} />%
              </h5>
              <p>of students are more interested in pursuing a STEM career</p>
            </div>
            <div
              className="col-6 col-lg-3 text-center mb-30 mb-lg-0"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h5>
                <CountUp end={80} duration={3.75} />
                %+
              </h5>
              <p>Over 80% completion rate in 8-month virtual courses</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
