import ajDiDonato from 'assets/img/about/young-advisory-board/aj-diDonato.jpg'
import annieLuc from 'assets/img/about/young-advisory-board/annie-luc.jpg'
import christieMatthews from 'assets/img/about/young-advisory-board/christie-matthews.jpg'
import franciscaVasconcelos from 'assets/img/about/young-advisory-board/francisca-vasconcelos.jpg'
import guyCassetta from 'assets/img/about/young-advisory-board/guy-cassetta.png'
import sujayShetty from 'assets/img/about/young-advisory-board/sujay-shetty.jpg'
import trevorGullstad from 'assets/img/about/young-advisory-board/trevor-gullstad.jpg'
import kellyEllis from 'assets/img/about/young-advisory-board/kelly-ellis.jpg'
import mcKellWoodland from 'assets/img/about/young-advisory-board/mcKell-woodland.jpg'

import matthewTribby from 'assets/img/about/young-advisory-board/mattTribby.jpg'
import andyWalner from 'assets/img/about/young-advisory-board/andyWalner.jpg'
import conradMitchell from 'assets/img/about/young-advisory-board/conradMitchell.jpg'
import simranChowdhry from 'assets/img/about/young-advisory-board/simran.jpg'
import juliaPudar from 'assets/img/about/young-advisory-board/julia.jpg'
import alexMikulich from 'assets/img/about/young-advisory-board/alexMikulich.jpg'
import benFenelon from 'assets/img/about/young-advisory-board/benFenelon.jpg'
import estherWong from 'assets/img/about/young-advisory-board/estherWong.jpg'
import tessaSharma from 'assets/img/about/young-advisory-board/tessaSharma.jpg'

import focalSystems from 'assets/img/common/focal-systems.png'
import coco from 'assets/img/common/coco-logo.png'
import rhodes from 'assets/img/common/rhodes.png'
import google from 'assets/img/common/google.png'
import salesforce from 'assets/img/common/salesforce.png'
import mdAnderson from 'assets/img/common/md-anderson.webp'
import mongo from 'assets/img/common/mongo.svg'
import sofi from 'assets/img/common/sofi.svg'
import crowe from 'assets/img/common/crowe.svg'

import om1 from 'assets/img/common/om1.png'
import onehouse from 'assets/img/common/onehouse.png'
import kellogg from 'assets/img/common/kellogg-school-of-management.png'
import microsoft from 'assets/img/common/microsoft.png'
import universityOfMichigan from 'assets/img/common/university-of-michigan.png'
import palski from 'assets/img/common/palski-and-associates.png'
import fulgentGenetics from 'assets/img/common/fulgent-genetics.png'
import neurable from 'assets/img/common/neurable.png'
import kitwarInc from 'assets/img/common/kitwar-inc.png'

import { Fragment } from 'react'

export const youngProfessionals = [
  // {
  //   avatar: guyCassetta,
  //   name: 'Guy Cassetta',
  //   organization: google,
  //   organizationName: 'Google',
  // },
  // {
  //   avatar: natalieEthell,
  //   name: "Natalie Ethell",
  //   organization: microsoft,
  //   organizationName: "Microsoft",
  // },
  {
    avatar: ajDiDonato,
    name: 'AJ DiDonato III.',
    organization: mongo,
    organizationName: 'MongoDB',
  },
  {
    avatar: annieLuc,
    name: 'Annie Luc',
    organization: google,
    organizationName: 'Google',
  },
  {
    avatar: christieMatthews,
    name: 'Christie Matthews',
    organization: coco,
    organizationName: 'Focal Systems',
  },
  {
    avatar: franciscaVasconcelos,
    name: 'Fran Vasconcelos',
    organization: rhodes,
    organizationName: 'Rhodes',
  },
  {
    avatar: kellyEllis,
    name: 'Kelly Ellis',
    organization: sofi,
    organizationName: 'SoFi',
  },
  {
    avatar: sujayShetty,
    name: 'Sujay Shetty',
    organization: crowe,
    organizationName: 'Crowe',
  },
  {
    avatar: trevorGullstad,
    name: 'Trevor Gullstad',
    organization: salesforce,
    organizationName: 'Sales Force',
  },
  {
    avatar: mcKellWoodland,
    name: 'McKell Woodland',
    organization: mdAnderson,
    organizationName: 'MD Anderson Cancer Center',
  },
  {
    avatar: matthewTribby,
    name: 'Matthew Tribby',
    organization: om1,
    organizationName: 'OM1',
  },
  {
    avatar: andyWalner,
    name: 'Andy Walner',
    organization: onehouse,
    organizationName: 'onehouse',
  },
  {
    avatar: conradMitchell,
    name: 'Conrad Mitchell',
    organization: kellogg,
    organizationName: 'Kellogg School of Management',
  },
  {
    avatar: simranChowdhry,
    name: 'Simran Chowdhry',
    organization: microsoft,
    organizationName: 'Microsoft',
  },
  {
    avatar: juliaPudar,
    name: 'Julia Pudar',
    organization: universityOfMichigan,
    organizationName: 'University of Michigan',
  },
  {
    avatar: alexMikulich,
    name: 'Alex Mikulich',
    organization: palski,
    organizationName: 'Palski & Associates',
  },
  {
    avatar: benFenelon,
    name: 'Ben Fenelon',
    organization: kitwarInc,
    organizationName: 'Kitware Inc',
  },
  {
    avatar: estherWong,
    name: 'Esther Wong',
    organization: fulgentGenetics,
    organizationName: 'Fulgent Genetics',
  },
  {
    avatar: tessaSharma,
    name: 'Tessa Sharma',
    organization: neurable,
    organizationName: 'Neurable',
  },
]
