import { Fragment } from 'react'
import howToRegister from 'assets/img/train/two-weeks-pd-register.png'

export const courseOverview = [
  {
    tabTitle: 'Key Details',
    tabContent: {
      keyPoints: [
        {
          icon: 'fa-solid fa-map-location-dot',
          point: 'Virtual with live instruction',
        },
        {
          icon: 'fa-solid fa-calendar-days',
          point: 'July 21 - 25, 2025',
        },
        { icon: 'fa-solid fa-sack-dollar', point: 'FREE for participants' },
        {
          icon: 'fa-solid fa-clipboard-list',
          point: 'Prerequisites: U.S. high school educators',
        },
      ],
    },
  },
  {
    tabTitle: 'How It Works',
    tabContent: {
      headline: 'Educators participate in three daily components.',
      keyPoints: [
        {
          icon: 'fa-solid fa-person-chalkboard',
          point: 'Lecture',
        },
        {
          icon: 'fa-solid fa-flask',
          point: 'Lab with Teaching Assistant',
        },
        { icon: 'fa-solid fa-diagram-project', point: 'Homework or project' },
      ],
      description: (
        <Fragment>
          <span className="d-block mb-10">
            Class is held daily, Monday - Friday, 11:00 AM - 5:00 PM Eastern
            Standard Time. An additional 10 asynchronous hours to be completed
            during the week.
          </span>
          <span className="d-block mb-10">
            Participants who do not have any prior training in Python will be
            required to complete asynchronous modules and one synchronous
            session during the week of July 14-20.
          </span>
          <span className="d-block">
            At the end of the course, educators will have ML skills and
            knowledge to take back into their classrooms to continue the
            education for their students.
          </span>
        </Fragment>
      ),
      highlightedPoint: [
        // "Upon successful completion of the 2-week course, educators will have the opportunity to receive CEUs.",
      ],
    },
  },
  {
    tabTitle: "What you'll learn",
    tabContent: {
      descriptions: [
        "Educators will develop foundational skills in Python, data science, and machine learning. They'll experience AI at the intersection of fields such as healthcare, robotics and social media.",
        'One of the most important topics covered in the course is bias in AI in which educators will learn what it means to become a Responsible AI leader.',
      ],
      pointsList: [
        'ML Algorithms, such as KNN and k-means clustering',
        'Data visualizations',
        // 'Reinforcement Learning;',
        // 'Deep Learning;',
        // 'Natural Language Processing (NLP)',
      ],
    },
  },
  {
    tabTitle: 'How To Register',
    tabContent: {
      descriptions: [
        'This course is open to high school teachers at any U.S. public or charter high school.',
        "We're looking for participants with enthusiasm to learn skills in the field of AI and a passion to share AI/ML education with your students. No prior coding experience required.",
        'To register:',
        <Fragment>
          <ol>
            <li>
              Click the button below & create an account on the Pathfinders
              website
            </li>
            <li>
              Search for Intro to AI Bootcamp on the Pathfinders site{' '}
              <img
                className="d-block"
                src={howToRegister}
                style={{ maxWidth: 100, width: '100%' }}
                alt="How to register"
              />
            </li>
            <li>Register!</li>
          </ol>
        </Fragment>,
      ],
      highlightedPoint: [
        'Applications are accepted on a rolling basis - the earlier you apply the better chance you have of acceptance!',
      ],
      button: [
        {
          isExternal: true,
          buttonText: 'CREATE ACCOUNT & REGISTER',
          link: 'https://pathfinders.onwingspan.com/web/en/login?ref=%2Fpage%2Fhome',
        },
      ],
    },
  },
]
