import { Fragment } from "react";
import img from "assets/img/research/ds-research-program-overview.jpg";

export const programOverview = {
  title: (
    <Fragment>
      Program{" "}
      <span className="text-animated">
        <span>Overview</span>
      </span>
    </Fragment>
  ),
  subTitle: "Increase college competitiveness by being among the first high school students to conduct research in Data Science at a college level.",
  desc: (
    <Fragment>
      The National High School Research Program offers an unprecedented opportunity for students to develop tangible technical skills in one of the world's most sought after areas of tech - Data Science - which has applications across every industry from medicine to finance, defense, sports, and more.
      <br /> <br />
      Over five weeks, students will transform from having zero knowledge to a full skill set in data science, and will receive training in conducting research. Working closely with local and national mentors from government, industry, or academia, students will develop their own research projects using real-world datasets, culminating in presentations that can be showcased in their college applications.
    </Fragment>
  ),
  img: {
    src: img,
    alt: "Program Overview",
  },
};
