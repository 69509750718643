import { Fragment } from "react";

export const programDetails = {
  title: 'The five-week research programs consist of two main components:',
  card: [
    {
      cardTitle: 'Weeks 1-2:',
      cardDesc: [
        'Students receive intensive training in the respective technical area and develop all the skills needed to prepare them to work with their mentor conducting research at a college level.',
      ],
    },
    {
      cardTitle: 'Weeks 3-5:',
      cardDesc: [
        'Students put their technical and research skills into action by conducting virtual research alongside a government, industry, or academic faculty mentor. They also participate in special events such as expert panels, networking opportunities, local college tours to enhance their learning experience. In-lab research may also be an option depending on a student\'s location.',
      ],
    },
  ],
  fullCard: {
    cardTitle: <Fragment>Pre-Program Self-Study Component <small>(for TCS non-alumni students of the Quantum track only)</small></Fragment>,
    cardDesc: [
      <Fragment>Accepted students of the Quantum track who have not taken TCS’ <span className="text-italic">Introduction to Quantum Computing</span> year-long course will be required to complete an additional component in order to gain the foundational skills needed. This will include a self-paced video series (approximately 20 hours), check-ins, and 1-2 instructor-led training sessions.</Fragment>
    ]
  },
  disclaimer:
    'Upon completion of the program, students are presented with a certificate and have a research project to showcase on college apps.',
}
