import styles from "./styles.module.scss";

export const NHSRPApplication = () => {
  return (
    <div className={styles.trainAwsPdApplication}>
      <div className="container">
        <div className={styles.iframeContainer}>
          <iframe
            id="JotFormIFrame-250317737424153"
            title="Clone of The Coding School's 2025 National High School Research Programs"
            onload="window.parent.scrollTo(0,0)"
            allowtransparency="true"
            allow="geolocation; microphone; camera; fullscreen"
            src="https://form.jotform.com/250317737424153"
            frameborder="0"
          >
          </iframe>
        </div>
      </div>
    </div>
  );
};
