import { Fragment } from "react";
import img from "assets/img/research/maryland-what-students-learn.jpg";

export const whatStudentsLearn = {
  img: {
    src: img,
    alt: "What StudentsLearn",
  },
  title: (
    <Fragment>
      What Students{" "}
      <span className="text-animated">
        <span>Learn</span>
      </span>
    </Fragment>
  ),
  keypoints: [
    "How to conduct research at a college level",
    "Application of technology to real-world problem solving",
    "Research protocol",
    "In-depth skills in emerging tech",
    "Data wrangling, analysis & visualizations",
    "Best practices in developing research projects",
    "How to format research questions and interpret findings",
    "Professional written & oral presentation techniques",
  ],
  desc: "Alongside developing technical skills, students are trained to professionally present their findings to professors in a variety of presentation styles. Mentors will provide feedback to help students grow in their research and presentation skills. By the end of the program, students have comprehensive tech skills, skills in conducting research, a completed research project, and an understanding of how technology can be applied in their field of research to solve some of the world's most pressing issues.",
};
