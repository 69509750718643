import classNames from 'classnames'
import styles from './styles.module.scss'

export const ProgramDetails = props => {
  const { data } = props
  return (
    <section className={styles.programDetails}>
      <div className="container">
        <h4 className={styles.title}>{data?.title}</h4>
        <div className="row mb-50">
          {data?.card?.map((item, idx) => (
            <div
              className={classNames(styles.item, 'col-12 col-md-6')}
              key={idx}
            >
              <div className={classNames(styles.card, 'shadowedBox')}>
                <h5 className={styles.cardTitle}>{item?.cardTitle}</h5>
                {item?.cardDesc.map((desc, idx) => (
                  <p className={styles.cardDesc} key={idx}>
                    {desc}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
        {data?.fullCard ? (
          <div className="row mb-50">
            <div className={classNames(styles.item, 'col-12')}>
              <div className={classNames(styles.card, 'shadowedBox')}>
                <h5 className={styles.cardTitle}>{data?.fullCard?.cardTitle}</h5>
                {data?.fullCard?.cardDesc.map((desc, idx) => (
                  <p className={styles.cardDesc} key={idx}>
                    {desc}
                  </p>
                ))}
              </div>
            </div>
          </div>
        ) : null}
        {data?.disclaimer ? (
          <p className={classNames(styles.disclaimer, 'bg-blue-gradient')}>
            {data?.disclaimer}
          </p>
        ) : null}
      </div>
    </section>
  )
}
