import { Fragment } from 'react'
import bg from 'assets/img/research/university-columbia.jpg'
import dodstem from "assets/img/common/dod-stem.svg";

export const banner = {
  bg: bg,
  heading: (
    <Fragment>
      <span className="d-block mb-5 ds-research-title">
        National Data Science Research
      </span>
      <small className="d-block">DSRP Scholars Program</small>
      {/* <span className='font-seg-ui-reg fs-26'>Proudly funded by <img style={{ maxWidth: 200 }} className='invert-img' src={dodstem} alt="DOD STEM" /></span> */}
    </Fragment>
  ),
  subHeading: 'Develop data science skills | Conduct cutting-edge research using real-world data | Increase college competitiveness |',
  description: (
    <Fragment>
      <span className='fs-20 d-block'>Incoming 9<sup>th</sup> &mdash; 12<sup>th</sup> graders and rising college freshmen</span>
      <span className='fs-20 d-block'>July 7 - August 8, 2025</span>
      <span className='fs-20 d-block mt-20'>DSRP Scholars Program is open to students in <strong>New York, Washington D.C., Maryland, Virginia, Southern California,</strong> and <strong>rural areas across the United States</strong></span>
    </Fragment>
  ),
  links: [
    {
      linkText: 'Apply',
      link: 'https://form.jotform.com/250126051228142',
      isExternal: true,
    },
  ],
}
