import background from 'assets/img/research/logistics-bg.jpg'
import { Fragment } from 'react'

export const logistics = {
  background: background,
  title: 'Logistics',
  toggleData: [
    {
      sectionTitle: 'Data Science',
      logisticsList: [
        {
          subTitle: 'Grades',
          desc: 'Incoming 10th-12th graders + college freshmen in Maryland',
        },
        {
          subTitle: 'Dates & Time',
          desc: (
            <Fragment>
              July 7 - Aug 8, 2025
              <span className="d-block">Monday - Friday</span>
              <span className="d-block">12 - 4pm ET (9am - 1pm PT)</span>
              <small className="d-block">
                *Must be able to join live sessions
              </small>
            </Fragment>
          ),
        },
        {
          subTitle: 'Location',
          desc: (
            <Fragment>
              <span className="d-block mb-5">
                Virtual, with live instruction
              </span>
              <small className="d-block">
                *Special events such as campus or facility tours, community
                events, and speaker panels may periodically take place virtually
                or in-person depending on a student’s regional hub.
              </small>
            </Fragment>
          ),
        },
        {
          subTitle: 'Prerequisites',
          desc: (
            <Fragment>
              <span className="d-block mb-5">
                No prior STEM experience required; students will learn all
                skills required during the program.
              </span>
            </Fragment>
          ),
        },
        {
          subTitle: 'Eligibility',
          desc: (
            <Fragment>
              <span className="d-block">Data Science Research Program (DSRP) Scholars must be residents of:  New York, Washington D.C., Maryland, Virginia, Southern California or rural areas across the U.S.</span>
              <small className="d-block">*Military-connected are encouraged to apply.*</small>
            </Fragment>
          ),
        },
        {
          subTitle: 'Tuition',
          desc: (
            <Fragment>
              <span className='d-block mb-5'><span className='text-gold fs-18'>Free</span>, with a $750 stipend for successful completion of the program for accepted students to the DSRP Scholars Program</span>
              <small className="d-block">
                DSRP Scholars Research Program is fully funded, and all student stipends are provided, thanks to the U.S. Department of Defense (DoD) STEM, Defense STEM Education Consortium (DESC).
              </small>
            </Fragment>
          ),
        },
      ],
    },
    {
      sectionTitle: 'Quantum Computing',
      logisticsList: [
        {
          subTitle: 'Grades',
          desc: 'Incoming 10th-12th graders + college freshmen in Maryland'
        },
        {
          subTitle: 'Dates & Time',
          desc: (
            <Fragment>
              June 23 - July 25, 2025
              <span className="d-block">Monday - Friday</span>
              <span className="d-block">12 - 4pm ET (9am - 1pm PT)</span>
              <small className="d-block">
                *Must be able to join live sessions
              </small>
            </Fragment>
          ),
        },
        {
          subTitle: 'Location',
          desc: (
            <Fragment>
              <span className="d-block mb-5">
                Virtual, with live instruction
              </span>
              <small className="d-block">
                *Special events such as campus or facility tours, community
                events, and speaker panels may periodically take place virtually
                or in-person depending on a student’s regional hub.
              </small>
            </Fragment>
          ),
        },
        {
          subTitle: 'Prerequisites',
          desc: (
            <Fragment>
              <span className="d-block mb-5">
                TCS’ Introduction to Quantum Computing
              </span>
              <span className="d-block mb-5">-or-</span>
              <span className="d-block mb-">Pre-program Self-Study course</span>
            </Fragment>
          ),
        },
        {
          subTitle: 'Eligibility',
          desc: (
            <Fragment>
              <span className="d-block">Quantum Equitech Scholars is open to students in Maryland only</span>
              {/* <small className="d-block">*Military-connected students are encouraged to apply.*</small> */}
            </Fragment>
          ),
        },
        {
          subTitle: 'Tuition',
          desc: (
            <Fragment>
              <span className="d-block mb-5"><span className='text-gold fs-18'>Free</span> Free for accepted students to the Equitech Scholars Program</span>
              <small>The Quantum Equitech Scholars Quantum Research Program is fully funded thanks to Tedco’s Equitech Growth Fund Award.</small>
            </Fragment>
          ),
        },
      ],
    },
  ],
}
