import background from 'assets/img/research/logistics-bg.jpg'
import { Fragment } from 'react'

export const logistics = {
  background: background,
  title: 'Logistics',
  logisticsList: [
    {
      subTitle: 'Grades',
      desc: 'Incoming 10th - 12th graders and recent high school graduates',
    },
    {
      subTitle: 'Dates',
      desc: 'June 23 - July 25, 2025',
    },
    {
      subTitle: 'Time',
      desc: (
        <Fragment>
          Monday - Friday
          <span className="d-block">12 - 4pm ET</span>
          <small className="text-italic d-block">
            *Must be able to join live sessions
          </small>
        </Fragment>
      ),
    },
    {
      subTitle: 'Location',
      desc: (
        <Fragment>
          <span className="d-block mb-5">
            Virtual with live instruction
          </span>
          <span className="d-block">
            Research with Mentor: Virtual, In-Person or RemoteDates:
          </span>
        </Fragment>
      ),
    },
    {
      subTitle: 'Prerequisites',
      desc: (
        <Fragment>
          <span className="d-block mb-5">
            Basic knowledge in Python programming (variables, loops, functions); and
          </span>
          <span className="d-block mb-5">
            TCS’ Intro to AI, Intro to Quantum Computing, or the Pre-Program Self-Study Component
          </span>
        </Fragment>
      ),
    },
    {
      subTitle: 'Tuition',
      desc: (
        <Fragment>
          <span className='d-block mb-5'>5-week Research Program: $3995</span>
          <span className='d-block mb-5'>Pre-program Self-Study Component: $1000 (non-alumni students only)</span>
          <small className="text-italic d-block">
            Scholarships available for students with financial need.
          </small>
        </Fragment>
      ),
    },
  ],
}
