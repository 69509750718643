import { Player } from '@lottiefiles/react-lottie-player'
import dodStem from 'assets/img/train/dod-stem-dark.png'
import styles from './styles.module.scss'
import { Link } from 'react-router-dom'

export const TrainBanner = () => {
  return (
    <section className={styles.banner}>
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-12 col-xl-7">
            <div className={styles.headline} data-aos="zoom-in">
              <h1>
                <span className={styles.trainLogo}>
                  <span className={styles.tlGrayDark}>TR</span>
                  <span className="text-brand-light">AI</span>
                  <span className={styles.tlGrayDark}>N</span>
                  <span className={styles.tlGrayLight}>ING</span>
                </span>
                <span className="d-block text-blue-gradient">
                  The Next Generation of
                </span>{' '}
                <span className="text-brand-light d-inline-block">AI</span>{' '}
                LEADERS AND EDUCATORS
              </h1>
            </div>
            <div
              className={styles.trainPageTagline + ' mt-40 mb-20'}
              data-aos="fade-up"
              data-aos-duration="500"
            >
              <h3 className="mb-20 text-bold">
                <span className="d-block mb-10">
                  High School Research Program
                </span>
                <Link
                  to="/nhsrp"
                  className="btn btn-primary-gradient text-white"
                >
                  Learn More & Apply
                </Link>
              </h3>
              <h4>
                <span className="d-block mb-10">
                  FREE PD for High School Educators
                </span>
                <Link
                  className="btn btn-gradient-2 text-white"
                  to="/train-2-weeks-pd"
                >
                  Learn More & Register
                </Link>
              </h4>
            </div>
          </div>
          <div className="col-8 col-lg-6 col-xl-5 train-lottier-player mx-auto">
            <Player
              autoplay={true}
              loop={true}
              controls={true}
              src="https://assets2.lottiefiles.com/packages/lf20_wjngbsl7.json"
            ></Player>
          </div>
        </div>
      </div>
    </section>
  )
}
