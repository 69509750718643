import { Fragment } from 'react'
import infoSys from 'assets/img/train/summer-institute.png'
import TCSLogo from 'assets/img/common/TCS-logo.png'

export const banner = {
  bannerTitle: 'AI will change',
  rotatingWords: [
    'Healthcare',
    'Finance',
    'Policymaking',
    'Conservation',
    'Defense',
    'Education',
    'Aerospace',
    'Entertainment',
  ],
  bannerSubTitle: 'Prepare your students to be responsible ai leaders',
  secondSubTitle: [
    'Artificial Intelligence Professional Development for High School Educators',
    'Virtual',
    <Fragment>
      <span className="font-seg-ui-reg">July 21 - 25, 2025</span>
    </Fragment>,
  ],
  thirdSubTitle: (
    // <Fragment>
    //   <span className="d-block mw-80 fs-18">
    //     The application for this PD is now closed. <br />
    //     Please fill out the interest form below to hear about our next AI PD.
    //   </span>
    // </Fragment>
    <Fragment>
      <span className="text-bold">FREE - sponsored by </span>{' '}
      <img
        className="ml-20 bg-white p-10"
        style={{ maxWidth: 150 }}
        src={infoSys}
        alt="Info System"
      />
    </Fragment>
  ),
  button: {
    isExternal: true,
    title: 'Create Account & Register',
    link: 'https://pathfinders.onwingspan.com/web/en/login?ref=%2Fpage%2Fhome',
  },
  afterButtonText: (
    <Fragment>
      *To register: click on the button; create an account; search for{' '}
      <i>Intro to AI Bootcamp</i>{' '}
      <img
        src={TCSLogo}
        style={{ maxWidth: 40, width: '100%' }}
        alt="TCS Logo"
      />{' '}
      & Register.{' '}
    </Fragment>
  ),
}
