import { Banner } from 'component/Research/Banner'
import { CreatedBy } from 'component/Research/CreatedBy'
import { HowToApply } from 'component/TrainCourse/CourseOverview/components/HowToApply'
import { Logistics } from 'component/Research/Logistics'
import { Offerings } from 'component/Courses/Offerings'
import { Professors } from 'component/Research/Professors'
import { ProgramBenefits } from 'component/Research/ProgramBenefits'
import { ProgramCompletion } from 'component/Research/ProgramCompletion'
import { ProgramDetails } from 'component/Research/ProgramDetails'
import { ProgramOverview } from 'component/Research/ProgramOverview'
import { StudentsTestimonials } from 'component/Research/StudentsTestimonials'
import { Testimonial } from 'component/SummerPrograms/EmergingTechPrograms/components/Testimonial'
import { WhatStudentsLearn } from 'component/Research/WhatStudentsLearn'

import {
  banner,
  createdBy,
  howToApply,
  logistics,
  offerings,
  professors,
  programBenefits,
  programCompletion,
  programDetails,
  programOverview,
  studentsTestimonials,
  whatStudentsLearn,
  techRequirements,
} from 'data/marylandResearch'

import testimonalImg from 'assets/img/about/about-us-splash-bg.jpg'
import susan from 'assets/img/research/miss-susan.png'

import styles from './styles.module.scss'
import classNames from 'classnames'

export const MarylandResearch = () => {
  return (
    <div className={styles.research}>
      <Banner data={banner} hasOverlay={true} />
      <CreatedBy data={createdBy} multipleLogos />
      <ProgramOverview data={programOverview} />
      <ProgramBenefits data={programBenefits} />
      <div className="container my-50">
        <Testimonial
          wrapClassName={styles.testimony}
          overlayClassName={styles.overlay}
          background={testimonalImg}
          testimony="It is exciting to see high school students learn and practice data skills before they have even started college considering that students normally gain this level of knowledge at the undergraduate and graduate level."
          testifier="Susan McGregor - Professor, Columbia University Data Science Institute"
          testifierImg={susan}
        />
      </div>
      <StudentsTestimonials
        data={studentsTestimonials?.one}
        titleClass={'text-blue-gradient-2'}
        descriptionClass={'text-blue-gradient-2'}
      />
      <WhatStudentsLearn
        data={whatStudentsLearn}
        isBulletInline={true}
        isResearch={true}
      />
      <Professors data={professors} addGoogleCard={true} googleCardText="The Coding School is proud to have professional Data Scientists from Google’s Magic Eye Team mentoring and sharing their industry expertise with TCS' National High School Research Program students." addAdditionalPartners={true} />
      <Logistics data={logistics} hasBgOverlay={true} withToggle={true} />
      <ProgramDetails data={programDetails} />
      <StudentsTestimonials
        data={studentsTestimonials?.two}
        isImgFirst={true}
        titleClass={'text-blue-gradient-2'}
        descriptionClass={'text-blue-gradient-2'}
      />
      <ProgramCompletion data={programCompletion} />
      <div className="bg-gray py-50 text-center">
        <div className="container">
          <HowToApply data={howToApply} />
        </div>
      </div>
      <Offerings data={offerings} iconClassName={styles.icon} isNarrow={true} />
      <div className="bg-gray py-50 text-center">
        <div className="container">
          <HowToApply data={techRequirements} />
        </div>
      </div>

      <section className={styles.sectionDisclaimer}>
        <div className="container">
          <p className={classNames(styles.disclaimer, "text-center mb-0")}>
            <small>
              This material is based upon work supported by the Defense STEM Education Consortium (DSEC) and the U.S. Department of Defense (DoD) STEM. The views expressed in written materials or publications, and/or made by speakers, moderators, and presenters, do not necessarily reflect the official policies of the Department of Defense nor does mention of trade names, commercial practices, or organizations imply endorsement by the U.S. Government.
            </small>
          </p>
        </div>
      </section>
    </div>
  )
}
