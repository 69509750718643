import { Fragment } from 'react'

export const courseOverview = [
  {
    tabTitle: 'Key Details',
    tabContent: {
      keyPoints: [
        {
          icon: 'fa-solid fa-map-location-dot',
          point: 'Virtual with live instruction',
        },
        {
          icon: 'fa-solid fa-calendar-days',
          point: 'June 26 - July 21, 2023',
        },
        { icon: 'fa-solid fa-sack-dollar', point: 'FREE for participants' },
        {
          icon: 'fa-solid fa-clipboard-list',
          point: 'Prerequisites: U.S. high school educators',
        },
      ],
    },
  },
  {
    tabTitle: 'How It Works',
    tabContent: {
      headline: 'Educators participate in three weekly components.',
      keyPoints: [
        {
          icon: 'fa-solid fa-person-chalkboard',
          point: '2 hours Lecture',
        },
        {
          icon: 'fa-solid fa-flask',
          point: '2 hours Lab with Teaching Assistant',
        },
        { icon: 'fa-solid fa-diagram-project', point: 'Homework or project' },
      ],
      description:
        "Class is held daily, Monday - Friday, 11-5pm ET (8am-2pm PST). Additionally, they'll have the opportunity to hear from AI experts from academia and industry. For supplemental academic support, we'll have weekly office hours and homework review sessions. At the end of the course, educators will have ML skills and knowledge to take back into their classrooms to continue the education for their students.",
      highlightedPoint: [
        'Upon successful completion of the 4-week course, educators will receive a stipend of $3,000 and an additional $1,000 stipend for completion of the follow-up surveys. Educators will also have the opportunity to receive CEUs.',
      ],
    },
  },
  {
    tabTitle: "What you'll learn",
    tabContent: {
      descriptions: [
        "Educators will develop foundational skills in Python, data science, and machine learning. They'll experience AI at the intersection of fields such as healthcare, robotics and social media.",
        'In addition to learning real AI skills, educators will meet AI experts at top universities such as Stanford, Johns Hopkins. One of the most important topics covered in the course is bias in AI in which educators will learn what it means to become a responsible AI leader.',
      ],
      pointsList: [
        'ML Algorithms, such as KNN and k-means clustering',
        'Data visualizations',
        'Reinforcement Learning',
        'Deep Learning',
        'Natural Language Processing (NLP)',
      ],
    },
  },
  {
    tabTitle: 'How To Apply',
    tabContent: {
      descriptions: [
        'This course is open to all U.S.based high school educators. To apply, educators are required to complete a short application form and submit a 250 word Statement of Interest',
        "We're looking for participants with enthusiasm to learn skills in the field of AI and a passion to share AI education with your students.",
        'Priority will be given to educators who can commit to bringing ML education back into their classrooms. We encourage educators serving students who are underrepresented in STEM or those located in STEM deserts to apply.',
      ],
      highlightedPoint: [
        <Fragment>
          <span className="text-bold">
            Applications are accepted on a rolling basis - the earlier you apply
            the better chance you have of acceptance.
          </span>
        </Fragment>,
        'No prior coding experience required, so submit your application today!',
      ],
      button: [
        {
          isExternal: true,
          buttonText: 'Apply Now',
          link: 'https://airtable.com/shrJhjWH4bfT3aJUm',
        },
      ],
    },
  },
]
