import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { footerLinks, socialLinks } from 'data/links'
import { year } from 'helpers'
import TCSLogo from 'assets/img/common/TCS-logo.png'

import styles from './styles.module.scss'

export const Footer = () => {
  const location = useLocation()
  let route = location.pathname.slice(1)
  return (
    <div className={styles.footer} id="footer">
      <div className={styles.footerTop}>
        <div className="container">
          <div className="d-flex flex-wrap align-items-center">
            <img src={TCSLogo} alt="Logo" className={styles.footerLogo} />
            {route !== 'subscribe' ? (
              <div className={styles.subscriberForm}>
                <div className={styles.subscribeBtn}>
                  <p className="fs-16 fw-6 mb-0 text-uppercase">Stay Updated</p>
                  <p className="fs-14 fw-6 mb-15">
                    Subscribe to receive the latest TCS updates and course
                    offerings
                  </p>
                  <div
                    className="mb-15"
                    id="fd-form-6088a1d675b152316cddc99f"
                  ></div>
                  <p className="fs-12">
                    By subscribing you agree to all terms & conditions under our{' '}
                    <a
                      className="text-gold"
                      href="https://docs.google.com/document/d/1DVu2Zkr1Y2fogCavvlc9CCwx-9i2fWzuKDp07ohWSkc/edit"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </p>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>

          <div className="row mt-15">
            <div className="col-md-4">
              <p style={{ lineHeight: 2, fontSize: 13 }}>
                The Coding School is a 501(c)(3) dedicated to training the
                future workforce in emerging technologies. Our public charitable
                registration number is 46-5097610.
              </p>
              <ul
                className={classNames(styles.socialShare, 'd-none d-md-block')}
              >
                {socialLinks.map((item, idx) => {
                  return (
                    <li key={idx}>
                      <a
                        href={item.socialLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.socialIcon}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>

            <div className="offset-md-1 col-md-3">
              <h5 className="fs-16 fw-6 text-uppercase mb-20">Useful Links</h5>
              <ul
                className={classNames(styles.footerCta, 'list-unstyled mb-0')}
              >
                {footerLinks.map((item, index) =>
                  item.external ? (
                    <li key={index}>
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.label}
                      </a>
                    </li>
                  ) : (
                    <li key={index}>
                      <Link to={item.link}>{item.label}</Link>
                    </li>
                  )
                )}
              </ul>
            </div>

            <div className="col-md-4 mt-30 mt-md-0">
              <h5 className="fs-16 fw-6 text-uppercase mb-20">Contact Info</h5>
              <ul
                className={classNames(styles.footerCta, 'list-unstyled mb-0')}
              >
                <li>
                  <a href="tel:3237909992">
                    <i className="fa-sharp fa-solid fa-phone-volume"></i>
                    <span>323-790-9992</span>
                  </a>
                </li>
                <li>
                  <a href="mailto:info@the-cs.org">
                    <i className="fa-solid fa-envelope"></i>
                    <span>info@the-cs.org</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://goo.gl/maps/4J4LUr46rXAfkwVt9"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-sharp fa-solid fa-location-dot"></i>
                    <span>
                      3940 Laurel Canyon Blvd. Ste 153 <br />
                      Los Angeles, CA 91604
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://docs.google.com/document/d/1DVu2Zkr1Y2fogCavvlc9CCwx-9i2fWzuKDp07ohWSkc/edit"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-sharp fa-solid fa-shield-halved"></i>{' '}
                    <span>Privacy Policy</span>
                  </a>
                </li>
              </ul>
              <ul
                className={classNames(
                  styles.socialShare,
                  'd-block d-md-none mt-30'
                )}
              >
                {socialLinks.map((item, idx) => {
                  return (
                    <li key={idx}>
                      <a
                        href={item.socialLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.socialIcon}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <div className="container">
          <p>Copyright &copy; {year}. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  )
}
