import background from 'assets/img/research/logistics-bg.jpg'
import { Fragment } from 'react'

export const logistics = {
  background: background,
  title: 'Logistics',
  logisticsList: [
    {
      subTitle: 'Grades',
      desc: 'Incoming 9th-12th graders + college freshmen',
    },
    {
      subTitle: 'Dates & Time',
      desc:
        <Fragment>
          July 7 - Aug 8, 2025
          <span className="d-block">Monday - Friday</span>
          <span className="d-block">12 - 4pm ET (9am - 1pm PT)</span>
          <small className="d-block">*Must be able to join live sessions</small>
        </Fragment>
      ,
    },
    {
      subTitle: 'Location',
      desc: (
        <Fragment>
          <span className="d-block mb-5">Virtual, with live instruction</span>
          <small className="d-block">
            Special events such as campus or facility tours, community events,
            and speaker panels may periodically take place virtually or
            in-person depending on a student’s regional hub.
          </small>
        </Fragment>
      ),
    },
    {
      subTitle: 'Prerequisites',
      desc: 'No prior STEM experience required; students will learn all skills required during the program.',
    },
    {
      subTitle: 'Eligibility',
      desc: <Fragment>
        <span className="d-block mb-5">DSRP Scholars must be residents of: <strong>New York, Washington D.C., Maryland, Virginia, Southern California</strong> or <strong>rural areas across the U.S.</strong></span>
        <small className='d-block'>**Military-connected students are encouraged to apply**</small>
      </Fragment>,
    },
    {
      subTitle: 'Tuition',
      desc: (
        <Fragment>
          <span className='d-block mb-5'><span className='text-gold fs-18'>Free</span>, with a $750 stipend for successful completion of the program.</span>
          <small className="d-block">
            DSRP Scholars Research Program is fully funded, and all student stipends are provided, thanks to the U.S. Department of Defense (DoD) STEM and the Defense STEM Education Consortium (DESC).
          </small>
        </Fragment>
      ),
    },
  ],
}
