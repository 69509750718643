import { Fragment } from 'react'
import bg from 'assets/img/research/university-columbia.jpg'

import google from 'assets/img/common/google.png'
import uom from 'assets/img/common/university-of-maryland.png'
import uci from 'assets/img/common/uci.png'
import disney from 'assets/img/common/disney.png'
import { ColumbiaUniversity } from 'component/Icons/ColumbiaUniversity'

export const banner = {
  bg: bg,
  heading: (
    <Fragment>
      <span className="d-block mb-5 ds-research-title">
        National High School Research Program
      </span>
      <small className="d-block">Equitech & DSRP Scholars Programs</small>
    </Fragment>
  ),
  // mentorsLogo: {
  //   logos: [
  //     { img: google, alt: "Google" },
  //     { img: uom, alt: "University of Maryland" },
  //     { svg: <ColumbiaUniversity height="80" />, alt: "University of Maryland" },
  //     { img: uci, alt: "University of California" },
  //     { img: disney, alt: "Disney" },
  //   ],
  //   text: "and many more..."
  // },
  subHeading: <Fragment>
    <span style={{ fontSize: '70%' }}>Conduct research in: </span>
    <span className='d-block mt-5'>Quantum Computing <span className='mx-10'>|</span> Data Science</span>
  </Fragment>,
  description: (
    <Fragment>
      <span className='fs-20 d-block'>Incoming 9<sup>th</sup> &mdash; 12<sup>th</sup> graders + rising college freshmen in
        Maryland</span>
      {/* <span className='fs-20 d-block'>July 7 - August 8, 2025</span> */}
      <span className='fs-20 d-block mb-20'>Virtual 5-week Summer Research Opportunities</span>
      <span className='fs-20 d-block'>Quantum Equitech & DSRP Scholars Programs are FREE to accepted students in the state of Maryland</span>
    </Fragment>
  ),
  links: [
    {
      linkText: 'Apply',
      link: 'https://form.jotform.com/250359452916159',
      isExternal: true,

    },
  ],
}
