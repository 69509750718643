import { useRef } from 'react'
import { Helmet } from 'react-helmet'
import classNames from 'classnames'
import CountUp from 'react-countup'

import { year } from 'helpers'
import giveLively from 'assets/img/donate/powered-by-give-lively.png'
import testimonalImg from 'assets/img/about/about-us-splash-bg.jpg'
import rohan from 'assets/img/donate/rohan.png'

import styles from './styles.module.scss'
import { CircularChart } from 'component/Common/Icons'
import { Testimonial } from 'component/SummerPrograms/EmergingTechPrograms/components/Testimonial'

export const Donate = () => {
  const donateRef = useRef(null)
  const scrollToDonate = () => {
    donateRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="The Coding School awarded $3 million grant"
        />
        <script>
          gl=document.createElement('script');gl.src='https://secure.givelively.org/widgets/simple_donation/coding-school.js?show_suggested_amount_buttons=true&show_in_honor_of=true&address_required=false&has_required_custom_question=null&suggested_donation_amounts[]=25&suggested_donation_amounts[]=50&suggested_donation_amounts[]=100&suggested_donation_amounts[]=250';document.getElementsByTagName('head')[0].appendChild(gl);
        </script>
      </Helmet>

      <div className={styles.donatePage} ref={donateRef}>
        <div className={styles.banner}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-6">
                <div className={styles.giveLively}>
                  <header>
                    <h2 className={styles.title}>Donate Now</h2>
                    <p className={styles.content}>
                      Giving the gift of coding not only changes a child's life;
                      it has the potential to change the world.
                    </p>
                  </header>
                  <div id="give-lively-widget"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-30 bg-gray-2">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4 mb-30 mb-md-0">
                <div className={styles.countWrap}>
                  <h6 className={styles.count}>
                    <CountUp end={50000} duration={2.2} />
                  </h6>
                  <p className={styles.content}>Students Taught</p>
                </div>
              </div>
              <div className="col-12 col-md-4 mb-30 mb-md-0">
                <div className={styles.countWrap}>
                  <h6 className={styles.count}>
                    <CountUp end={75} duration={2.2} />%
                  </h6>
                  <p className={styles.content}>Socioeconomically Disadvantaged</p>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className={styles.countWrap}>
                  <h6 className={styles.count}>
                    <CountUp end={2000} duration={2.2} />+
                  </h6>
                  <p className={styles.content}>Received high school credit</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={classNames(styles.impact, 'py-50')}>
          <div className="container">
            <h3 className={classNames(styles.title, 'mb-30')}>
              Your donation will be used to make emerging tech education more
              accessible to students across the country
            </h3>
            <p className={classNames(styles.content, 'mb-50 text-center')}>
              Because every student deserves access to high quality STEM
              education and the opportunities it unlocks for life.
            </p>

            <header>
              <h2 className={styles.title}>Why TCS?</h2>
              <p className={classNames(styles.content, 'mb-50 text-center')}>
                At TCS, we are actively working to ensure all students are equipped with the skills they will need to
                succeed as future members of the workforce. For the past 10
                years, our programs have made a tangible difference in the
                trajectory of students' educational journeys and beyond.
              </p>
            </header>
            <div className="row mb-50">
              <div className="col-12 col-md-4 mb-30 mb-md-0">
                <div
                  className={classNames(
                    styles.infoWrap,
                    'shadowedBox bg-gradient-2'
                  )}
                >
                  <CircularChart
                    completion="99"
                    outlineColor="#005d92"
                    fillColor="#fff"
                    className={styles.icon}
                    isCountUp={true}
                  />
                  <p>
                    of students are more likely to take a STEM class in college
                    after a TCS course
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div
                  className={classNames(
                    styles.infoWrap,
                    'shadowedBox bg-gradient-2'
                  )}
                >
                  <CircularChart
                    completion="95"
                    outlineColor="#005d92"
                    fillColor="#fff"
                    className={styles.icon}
                    isCountUp={true}
                  />
                  <p>
                    of students are more interested in pursuing a STEM career
                    after a TCS course
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-4 mb-30 mb-md-0">
                <div
                  className={classNames(
                    styles.infoWrap,
                    'shadowedBox bg-gradient-2'
                  )}
                >
                  <CircularChart
                    completion="81"
                    outlineColor="#005d92"
                    fillColor="#fff"
                    className={styles.icon}
                    isCountUp={true}
                  />
                  <p>
                    of students feel increased confidence in their STEM skills
                    after a TCS course
                  </p>
                </div>
              </div>
            </div>
            <button
              className={classNames(
                styles.donateBtn,
                'btn btn-brand text-white'
              )}
              onClick={scrollToDonate}
            >
              Donate Now
            </button>
          </div>
        </div>

        <div className="container">
          <Testimonial
            wrapClassName={styles.testimony}
            background={testimonalImg}
            testimony="I am so thankful as my family are homeless and I would never have been able to do this course if it wasn't free. This is the beginning of my journey and future career and it is an amazing and exciting opportunity."
            testifier="Rohan, 9th Grade, Introduction to Quantum Computing Course"
            testifierImg={rohan}
            isSideWays={true}
            quoteClassName={styles.quote}
          />
        </div>

        <div className={styles.creditSec}>
          <div className="container">
            <div className="col-md-12">
              <p className={styles.subTitle}>
                The Coding School has partnered with Give Lively to securely
                process your online donation. This transaction will appear on
                your bank statement as "The Coding School". This transaction is
                subject to Give Lively's{' '}
                <a
                  href="https://www.givelively.org/terms-of-use"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms
                </a>{' '}
                and{' '}
                <a
                  href="https://www.givelively.org/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy.
                </a>
              </p>
              <p className={styles.subTitle}>
                Need help? Email{' '}
                <a href="mailto:support@givelively.org">
                  support@givelively.org
                </a>
              </p>
              <p className={styles.poweredBtn + ' btn'}>
                <img
                  src={giveLively}
                  className={styles.giveLively}
                  alt="powered-by-give-lively"
                />
              </p>
              <p className={styles.subTitle + ' mb-0'}>
                © Give Lively LLC {year}. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
