import google from 'assets/img/common/google.png';
import columbiaUniversity from 'assets/img/common/columbia-university.svg';
import harvard from 'assets/img/common/harvard.png';
import mit from 'assets/img/common/mit.png';
import stanford from 'assets/img/common/stanford-words-logo.png';
import uom from 'assets/img/common/university-of-maryland.png';
import ucBerkeley from 'assets/img/common/uc-berkeley.png';

export const createdBy = {
  tagline: "Learn from world class mentors in academia, government and industry from",
  logos: [
    // { src: google, alt: "Google" },
    { src: columbiaUniversity, alt: "Columbia University" },
    { src: harvard, alt: "Harvard" },
    { src: mit, alt: "MIT" },
    { src: stanford, alt: "Stanford" },
    { src: uom, alt: "University of Maryland" },
    { src: ucBerkeley, alt: "UC Berkeley" },
  ],
};
