import { Fragment } from 'react'
import background from 'assets/img/home/k-12-research-opportunity.jpg'

export const programBenefits = {
  background: background,
  title: (
    <Fragment>
      Program{' '}
      <span className="text-animated">
        <span>Benefits</span>
      </span>
    </Fragment>
  ),
  keypoints: [
    'Increase college competitiveness',
    'Create a research project for college or internship portfolios',
    'Explore real world applications of emerging tech research',
    'Build relationships with local academic, government, and industry professionals',
    'In-person or virtual speaker panels and tours of research labs, campuses, & military facilities',
    'Develop transferable STEM research abilities',
  ],
  desc: [
    // "Students will have the opportunity to meet some of the most revered experts in the fields of AI, quantum computing and data science; take virtual tours of military research labs; and hear from panels of experts from government, industry and academia, sharing career paths, their journeys and latest findings. In-person sessions may be available based on the student's geographical location.",
    <Fragment>
      Whether students are looking to learn more about how emerging tech is
      applicable to their own interests, ethics in research, or get a head start
      on research before college, they will all gain knowledge and experience
      <span className='text-gold'> rarely offered to high school students</span>.
    </Fragment>
  ],
}
