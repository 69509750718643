import { Fragment } from "react";

export const howToApply = {
  title: (
    <Fragment>
      How To{" "}
      <span className="text-animated">
        <span>Apply</span>
      </span>
    </Fragment>
  ),
  descriptions: [
    "Due to the overwhelming interest in the National High School Research Program, students are required to submit a Statement of Interest of 250-300 words that highlights their interest in continuing to explore the emerging tech field and conducting research, future university and career aspirations, how they feel this program may help them attain those goals, and any other relevant information.",
    "We are looking for students with passion, interest and curiosity to learn more about these fields.",
  ],
};
