import { Link } from 'react-router-dom'
import classNames from 'classnames'

import keysight from 'assets/img/common/keysight-logo.svg'

import styles from './styles.module.scss'

export const BringAiCards = () => {
  return (
    <section className={styles.BringAiCards}>
      <div className={classNames(styles.containerBig, 'container')}>
        <div className="row justify-content-center">
          <div className={classNames(styles.bacFirst, 'col-12 col-md-6')}>
            <div
              className={styles.main + ' text-center text-md-left'}
              data-aos="fade-up"
              data-aos-duration="700"
            >
              <div className={styles.headline}>
                <h4>FREE Workshops in Emerging Technologies</h4>
                <h5>
                  Funded by <img src={keysight} alt="Keysight" />
                </h5>
              </div>
              <div className={styles.desc}>
                <p>
                  January through June of 2025, The Coding School will offer
                  <span className="text-bold text-underline"> free</span>,
                  1-hour virtual workshops customized to your middle and high
                  school classrooms across the United States. These engaging
                  sessions will feature interactive discussions, hands-on
                  activities, and real-world applications, helping students
                  explore foundational concepts, career opportunities, and
                  essential skills in cutting-edge industries.
                </p>
              </div>
              <a
                href="https://airtable.com/appAgeNP0hq6rXtT0/pagifuoMwrfUdxzoB/form"
                className={
                  styles.button +
                  ' btn btn-primary-gradient text-upper text-white text-bold py-10 rounded-0'
                }
                target="_blank"
                rel="noreferrer"
              >
                Request a workshop
              </a>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div
              className={styles.main + ' text-center text-md-left'}
              data-aos="fade-up"
              data-aos-duration="700"
            >
              <div className={styles.headline}>
                <h4>Professional Development Course For Educators</h4>
              </div>
              <div className={styles.desc}>
                <p>
                  High school educators have two professional development (PD)
                  opportunities: a 1-week summer intensive or participating in
                  the two-semester Introduction to ML course, in which educators
                  will have a weekly lab section specifically designed for
                  teachers. Educators will develop in-depth ML knowledge,
                  receive curriculum they can integrate into their existing
                  curricula, and join an online community of like-minded
                  educators who are bringing AI into their classrooms. Teachers
                  will have an opportunity to earn CEUs upon successful
                  completion of the training.
                </p>
              </div>
              <a
                href="https://pathfinders.onwingspan.com/web/en/login?ref=%2Fpage%2Fhome"
                className={
                  styles.button +
                  ' btn btn-primary-gradient text-upper text-white text-bold py-10 rounded-0'
                }
                target="_blank"
                rel="noreferrer"
              >
                Create Account & Register
              </a>
              <p>
                <small>
                  Create an account & register for Intro to AI Bootcamp by
                  clicking above
                </small>
              </p>
            </div>
          </div>

          {/* <div className="col-12 col-md-6 mt-30">
            <div
              className={classNames(styles.main, 'text-center text-md-left')}
              data-aos="fade-up"
              data-aos-duration="600"
            >
              <div className={styles.headline}>
                <h4>Offer As A For-Credit Or Afterschool Course</h4>
              </div>
              <div className={styles.desc}>
                <p>
                  <span className="text-bold">
                    Nationally accredited by WASC
                  </span>
                  , we offer our two-semester{' '}
                  <span className="text-italic">Introduction to ML</span> course
                  for high school credit at participating schools and school
                  districts. We provide the weekly instruction; assignments,
                  including problem sets, homework and projects; and grading and
                  transcripts; all that we require of participating schools is
                  that they approve to offer the course for transcript credit.
                  We work with individual schools, school districts, and
                  statewide DOEs. This course is fully funded by DoDSTEM and
                  there is no cost to our school partners or students. To learn
                  more about how to offer this course for credit complete the
                  form below.
                </p>
              </div>
              <Link
                className={
                  styles.button +
                  ' btn btn-primary-gradient text-upper text-white text-bold py-10 rounded-0'
                }
                to="/school-partner-info"
              >
                Connect with Us
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  )
}
