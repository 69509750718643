import { Fragment } from 'react'
import img from 'assets/img/research/program-overview.png'

export const programOverview = {
  title: (
    <Fragment>
      Research Program{' '}
      <span className="text-animated">
        <span>Overview</span>
      </span>
    </Fragment>
  ),
  desc: (
    <Fragment>
      The Coding School has developed an unprecedented research experience for
      high school students. Partnered with universities across the country
      including Columbia, University of Maryland, and University of California,
      each student will have a unique learning experience including delving deep
      and gaining tangible technical skills, applying those to conducting
      research, serving as research assistants, and contributing to the fields
      of AI/ML or Quantum research. Working with local and national mentors from
      academia, industry and government, students will culminate the program
      presenting their findings and projects at the TCS Summer Research
      Symposium and with invaluable experiences to showcase in their college
      applications.
      <span className="d-block mt-15">
        Students have the choice of applying to the{' '}
        <span className="text-bold text-gold">AI</span> or{' '}
        <span className="text-bold text-gold">Quantum</span> Research track.
      </span>
    </Fragment>
  ),
  img: {
    src: img,
    alt: 'Program Overview',
  },
}
