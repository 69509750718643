import { Fragment } from "react";
import img from "assets/img/research/program-overview.png";

export const whatStudentsLearn = {
  img: {
    src: img,
    alt: "What StudentsLearn",
  },
  title: (
    <Fragment>
      What Students{" "}
      <span className="text-animated">
        <span>Learn</span>
      </span>
    </Fragment>
  ),
  keypoints: [
    "Data analysis",
    "Data visualization",
    "Web scraping",
    "Data cleaning and wrangling",
    "Research protocol",
    "Programming skills in R",
    "How to develop research projects",
    "Techniques for oral presentations"
  ],
  desc: "Alongside developing technical skills, students are trained to professionally present their findings to professors in both lightning pitches and full-style presentations. Mentors will provide feedback to help students grow in their research and presentation skills. By the end of the program, students have comprehensive programming skills in R, technical skills in data science and research, a completed research project, and an understanding of how data science can be applied in their field of research.",
};
