import { Fragment } from "react";

export const howToApply = {
  title: (
    <Fragment>
      How To{" "}
      <span className="text-animated">
        <span>Apply</span>
      </span>
    </Fragment>
  ),
  descriptions: [
    "Please fill out the application form at the link below. Due to the interest in this research program, students are required as part of the application form to submit a Statement of Interest of 200+ words that highlights their interest in learning data science and conducting research, future university and career aspirations, and any other relevant information.",
    "We are looking for students of all backgrounds and life experiences with passion, interest and curiosity to learn more about these fields.",
    "Students will be accepted into the program on a rolling basis, so please be sure to check your email!",
  ],
  button: [
    {
      link: 'https://form.jotform.com/250126051228142',
      buttonText: 'Apply',
      isExternal: true,
    },
  ],
};
