import studentImgOne from "assets/img/research/testimonials-one.png";
import studentImgTwo from "assets/img/research/testimonials-two.png";

export const studentsTestimonials = {
  one: {
    title: "Hear from Our Students",
    desc: "How do you feel you benefited from this program?",
    studentImg: studentImgOne,
    testimonials: [
      {
        testimony:
          "This program was the best! For someone who had no idea about quantum computing, this is a great course to learn the foundations and go even further in a specific field of quantum with a research mentor. Other programs would most likely have studying books and passing exams, but in this course, you get taught by professional people and use what you learned to solve your own research tasks. My interest in pursuing research and quantum computing greatly increased after this course.",
        testifier: "High School student, MD",
      },
      {
        testimony:
          "I gained significant research experience, and I learned an entire coding language. This program boosts my college app, and only spiked my interest in statistics and data science more with real life applications to the concepts I was already fascinated about.",
        testifier: "11th grade student, CA",
      },
      {
        testimony:
          "The QxQ HSQRP was amazing for my interest in quantum computing. I was able to work under two amazing professors who taught me a lot about both the hardware and software aspects of quantum computing. Interacting with undergraduate students doing research in quantum computing was also super helpful in showing me what research might look like for me in the near future. Overall, this was a great experience, and I'm looking forward to do quantum computing research in college.",
        testifier: "High School student, OH",
      },
      {
        testimony:
          "I have definitely increased my data science skills and I am more confident in my STEM ability. I think that this program definitely increased my chances of going into a Machine Learning job.",
        testifier: "11th grade student, NY",
      },
      {
        testimony:
          "This program was amazing.  Perhaps the most valuable part of this program for me was helping me understand that physics, specifically quantum physics, was the place for me. As I prepare to apply to college, this program provided me with impressive and welcoming mentors that talked with me to help me understand the best track. I learned valuable skills such as data analysis, Python, working in a group, setting up optical elements, presenting findings, extracting information from a research paper, and just working in a lab in general. Our group specifically was able to work on both the theory and experimental aspects of quantum research, which just gave me a more broad experience.  This was probably my favorite part of this program: making me more open to a future in the previously unfamiliar field of theoretical quantum physics.",
        testifier: "10th grade student, MD",
      },
      {
        testimony:
          "Throughout the past 5 weeks, I learned many things, such as how to learn to code in R and the different types of machine learning models. I feel like this program really helped me understand what I'm truly interested in as a major and career!",
        testifier: "12th grade student, CA",
      },
      {
        testimony:
          "I learned a lot about coding with R and about just conducting research in general. Again, the organization of the whole program was impeccable and everything felt so well put together and easy to understand, making the learning experience all the more enjoyable.",
        testifier: "11th grade student",
      },
      {
        testimony:
          " I learned more about coding and working with datasets. I feel more prepared with potential classes I am going to take in college.",
        testifier: "10th grade student, NY",
      },
      {
        testimony:
          "Through this program, I am now more interested in data science being a future career.",
        testifier: "10th grade student, TX",
      },
      {
        testimony:
          "I feel that I really benefited in so many ways from this program. I now know how to visualize data so that people around me can visualize it clearly. I learned how to present to others very well, so I now can present my data without being hesitant. I feel that if I were to do presentations or reports in college, I now have a full grasp on the concepts and what's needed to integrate my data and information into those presentations. I feel even more interested in data science now because of the experience I have gained with coding and research.",
        testifier: "11th grade student, CA",
      },
      {
        testimony:
          "I learned a new programming language and learned a lot about data science. I learned how to form questions on data and built effective models, performed research, and gained insights on the data I analyzed in order to reach a conclusion.",
        testifier: "9th grade student, NJ",
      },
    ],
  },
  two: {
    title: "More from Our Students",
    desc: "What would you tell your friend about this program?",
    studentImg: studentImgTwo,
    testimonials: [
      {
        testimony:
          "It was a unique experience that I will never forget. This program helped me reinforce my coding skills, and allowed me to learn new things that I can take with me into the future.",
        testifier: "12th grade student, CA",
      },
      {
        testimony:
          "I would let them know that I learned a lot from this program and it was an awesome experience.",
        testifier: "10th grade student, WA",
      },
      {
        testimony:
          "This experience allows you to work with someone so knowledgeable that each day you can learn what you want to become. Although this experience has restated my passion for pursuing a career in research in physics, it has also provided a topic that will affect my future daily life. Quantum computing is the future of research, and learning about the path that is being built at this moment is a priceless moment in our lives.",
        testifier: "High School student, FL",
      },
      {
        testimony:
          "I really learned a lot from the program, and while it was pretty tough at times, looking back at where I started compared to my end result, it really is such a huge difference and I never could have imagined creating something like this.",
        testifier: "9th grade student, NJ",
      },
      {
        testimony:
          "The program is great as it gives highschool students full skills in data science. Something most high school students wouldn't have much prior knowledge in before college.",
        testifier: "10th grade student, OH",
      },
      {
        testimony:
          "This program is incredibly beneficial for gaining an introduction to data science with R as well as some real-world experience with research projects!",
        testifier: "11th grade student, CA",
      },
      {
        testimony:
          "That this was a very intensive, very rewarding, very fun research experience concerning Data Science.",
        testifier: "11th grade student, MD",
      },
      {
        testimony:
          "I would say it's really well organized and they should definitely apply to the program as it will give them a lot of useful knowledge and skills.",
        testifier: "11th grade student, VA",
      },
      {
        testimony:
          "This program helped me in understanding languages that I had little to no knowledge in. It not only focused on teaching you the languages, it also made sure you understood exactly what you were learning and developing.",
        testifier: "10th grade student, TX",
      },
      {
        testimony:
          "My instructor was an amazing teacher and always really willing to help us with any issues that we faced.",
        testifier: "11th grade student, CA",
      },
      {
        testimony:
          "The independent research was really difficult at times, but it was so fulfilling when you finished.",
        testifier: "11th grade student, NC",
      },
    ],
  },
};
