import classNames from "classnames";
import styles from "./styles.module.scss";

export const ProgramOverview = (props) => {
  const { data } = props;
  return (
    <section className={styles.programOverview}>
      <div className="container">
        <div className="row align-items-center">
          <div className={classNames(styles.contentWrap, "col-12 col-lg-7")}>
            <h3 className={classNames(styles.title, "mb-20")}>{data?.title}</h3>
            <h4 className={classNames(styles.subTitle, "mb-20")}>{data?.subTitle}</h4>
            <p className={styles.desc}>{data?.desc}</p>
          </div>
          <div className="col-12 col-lg-5 d-none d-lg-block">
            <div className="img-highlighted right">
              <img
                className="img-fluid w-100"
                src={data?.img?.src}
                alt={data?.img?.alt}
              />
            </div>
          </div>
          {data?.fullWidthContent ? <div className="col-12">{data?.fullWidthContent}</div> : null}
        </div>
      </div>
    </section>
  );
};
