import { Fragment } from 'react'

export const whatStudentsLearn = {
  title: (
    <Fragment>
      What Students{' '}
      <span className="text-animated">
        <span>Learn</span>
      </span>
    </Fragment>
  ),
  sections: [
    {
      sectionTitle: 'Artificial Intelligence',
      accordion: [
        {
          title: 'Foundational Data Science',
          desc: 'Engage with the core aspects of data science, including data wrangling, cleaning, and engineering to prepare data for analysis and modeling.',
        },
        {
          title: 'Modeling Techniques',
          desc: 'Gain exposure to a wide range of modeling approaches including supervised, unsupervised, and reinforcement learning.',
        },
        {
          title: 'Deep Learning',
          desc: 'Dive into the basics of deep learning, exploring how these powerful models can be applied to tasks including computer vision and natural language processing tasks',
        },
        {
          title: 'Advanced Model Optimization',
          desc: 'Learn about advanced topics in model development, including hyperparameter tuning, validation techniques, and strategies for improving model performance',
        },
      ],
    },
    {
      sectionTitle: 'Quantum Computing',
      accordion: [
        { title: 'Quantum Algorithms', desc: 'Learn quantum algorithms including implementing quantum key distribution (QKD) and Grover\'s algorithm, as well as running variational quantum algorithms' },
        {
          title: 'Quantum Hardware',
          desc: 'Explore near term algorithms, current qubit landscape, and error correction methods',
        },
        {
          title: 'Quantum Networking',
          desc: 'Delve into quantum teleportation and entanglement swapping',
        },
        {
          title: 'Quantum Simulation',
          desc: 'Gain experience simulating quantum computers and simulating physical properties using quantum computers',
        },
      ],
    },
  ],
  paragraphs: [
    'The research training component of both programs will be an intensive review building upon the introductory year-long course material with a specific lens toward preparing students for research in the given field.',
    'During the research experience, students will learn first hand how research scientists approach problem solving using emerging technologies. Mentors will work closely with students, guiding them with best practices research protocol, helping them apply their research skills to answer technical questions, and coaching them in developing their research projects which they will have the opportunity to present at the Summer Research Symposium.',
  ],
}
