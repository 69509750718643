import { Fragment } from "react";

export const programDetails = {
  title: 'This five-week program consists of two main components:',
  card: [
    {
      cardTitle: 'Weeks 1-2:',
      cardDesc: [
        <Fragment>
          Students participate in a virtual research training program with TCS faculty, reviewing and building upon material from TCS’ <span className="text-italic">Introduction to Artificial Intelligence</span> or <span className="text-italic">Introduction to Quantum Computing</span> respectfully, while also learning useful vocabulary and professional skills to prepare to conduct research with their research hosts or mentors.
        </Fragment>,
      ],
    },
    {
      cardTitle: 'Weeks 3-5:',
      cardDesc: [
        'Students put their emerging technology skills into action while developing and/or assisting researchers with research projects that are overseen by university or industry mentors.',
      ],
    },
  ],
  fullCard: {
    cardTitle: <Fragment>Pre-Program Self-Study Component <small>(for non-alumni students)</small></Fragment>,
    cardDesc: [
      <Fragment>Accepted students who have not taken either TCS’ <span className="text-italic">Introduction to AI</span> or <span className="text-italic">Introduction to Quantum Computing</span> year-long course will be required to complete an additional component in order to gain the foundational skills needed. This will include a self-paced video series, check-ins, and 1-2 training sessions.</Fragment>
    ]
  },
  disclaimer:
    'Upon completion of the program, students are presented with a certificate and have a research project to showcase on college apps.',
}
