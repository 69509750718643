import studentImgOne from "assets/img/research/testimonials-one.png";
import studentImgTwo from "assets/img/research/testimonials-two.png";

export const studentsTestimonials = {
  one: {
    title: "Hear from Our Students",
    desc: "How do you feel you benefited from this program?",
    studentImg: studentImgOne,
    testimonials: [
      {
        testimony:
          "I gained significant research experience, and I learned an entire coding language. This program boosts my college app, and only spiked my interest in statistics and data science more with real life applications to the concepts I was already fascinated about.",
        testifier: "11th grade student, CA",
      },
      {
        testimony:
          "I have definitely increased my data science skills and I am more confident in my STEM ability. I think that this program definitely increased my chances of going into a Machine Learning job.",
        testifier: "11th grade student, NY",
      },
      {
        testimony:
          "Throughout the past 5 weeks, I learned many things, such as how to learn to code in R and the different types of machine learning models. I feel like this program really helped me understand what I'm truly interested in as a major and career!",
        testifier: "12th grade student, CA",
      },
      {
        testimony:
          "I learned a lot about coding with R and about just conducting research in general. Again, the organization of the whole program was impeccable and everything felt so well put together and easy to understand, making the learning experience all the more enjoyable.",
        testifier: "11th grade student",
      },
      {
        testimony:
          " I learned more about coding and working with datasets. I feel more prepared with potential classes I am going to take in college.",
        testifier: "10th grade student, NY",
      },
      {
        testimony:
          "Through this program, I am now more interested in data science being a future career.",
        testifier: "10th grade student, TX",
      },
      {
        testimony:
          "I feel that I really benefited in so many ways from this program. I now know how to visualize data so that people around me can visualize it clearly. I learned how to present to others very well, so I now can present my data without being hesitant. I feel that if I were to do presentations or reports in college, I now have a full grasp on the concepts and what's needed to integrate my data and information into those presentations. I feel even more interested in data science now because of the experience I have gained with coding and research.",
        testifier: "11th grade student, CA",
      },
      {
        testimony:
          "I learned a new programming language and learned a lot about data science. I learned how to form questions on data and built effective models, performed research, and gained insights on the data I analyzed in order to reach a conclusion.",
        testifier: "9th grade student, NJ",
      },
    ],
  },
  two: {
    title: "More from Our Students",
    desc: "What would you tell your friend about this program?",
    studentImg: studentImgTwo,
    testimonials: [
      {
        testimony:
          "It was a unique experience that I will never forget. This program helped me reinforce my coding skills, and allowed me to learn new things that I can take with me into the future.",
        testifier: "12th grade student, CA",
      },
      {
        testimony:
          "I would let them know that I learned a lot from this program and it was an awesome experience.",
        testifier: "10th grade student, WA",
      },
      {
        testimony:
          "I really learned a lot from the program, and while it was pretty tough at times, looking back at where I started compared to my end result, it really is such a huge difference and I never could have imagined creating something like this.",
        testifier: "9th grade student, NJ",
      },
      {
        testimony:
          "The program is great as it gives highschool students full skills in data science. Something most high school students wouldn't have much prior knowledge in before college.",
        testifier: "10th grade student, OH",
      },
      {
        testimony:
          "This program is incredibly beneficial for gaining an introduction to data science with R as well as some real-world experience with research projects!",
        testifier: "11th grade student, CA",
      },
      {
        testimony:
          "That this was a very intensive, very rewarding, very fun research experience concerning Data Science.",
        testifier: "11th grade student, MD",
      },
      {
        testimony:
          "I would say it's really well organized and they should definitely apply to the program as it will give them a lot of useful knowledge and skills.",
        testifier: "11th grade student, VA",
      },
      {
        testimony:
          "This program helped me in understanding languages that I had little to no knowledge in. It not only focused on teaching you the languages, it also made sure you understood exactly what you were learning and developing.",
        testifier: "10th grade student, TX",
      },
      {
        testimony:
          "My instructor was an amazing teacher and always really willing to help us with any issues that we faced.",
        testifier: "11th grade student, CA",
      },
      {
        testimony:
          "The independent research was really difficult at times, but it was so fulfilling when you finished.",
        testifier: "11th grade student, NC",
      },
    ],
  },
};
