import { Fragment } from "react";

import { Banner } from "component/SummerPrograms/Banner";
import { BecomeFutureLeader } from "component/SummerPrograms/BecomeFutureLeader";
import { EmergingTechPrograms } from "component/SummerPrograms/EmergingTechPrograms";

import { banner, emergingTech } from "data/programs";

export const Programs = () => {
  return (
    <div className="summerPrograms">
      <Banner data={banner} />
      <BecomeFutureLeader
        data={
          <Fragment>
            Don't just learn how to code… become a{" "}
            <span>future leader</span> in emerging tech
          </Fragment>
        }
      />
      <EmergingTechPrograms data={emergingTech} invertedQuotes={true} />
    </div>
  );
};
