import { Fragment } from 'react'

import infoSys from 'assets/img/train/summer-institute.png'

export const aiIntro = {
  paragraph: (
    <Fragment>
      AI will solve some of the most pressing issues of the 21st century. We
      want to make sure every high school student is prepared to become
      responsible AI leaders. That's why we've created,{' '}
      <i>Introduction to Artificial Intelligence (AI)</i>, a 1-week professional
      development course designed for all US-based public school and public
      charter school 9th - 12th grade teachers to gain cutting-edge knowledge
      and skills in AI and Machine Learning (ML) in order to bring this
      education back into their classrooms. Throughout the course, educators
      will work on real-world AI projects and join a like-minded community of
      educators.
    </Fragment>
  ),
  aiList: [
    'Learn real skills in AI/ML',
    'Bring ML into your classroom',
    'Community of STEM educators',
    'CEU opportunity',
    <Fragment>
      FREE - sponsored by{' '}
      <img
        className="ml-20 bg-white p-10"
        style={{ maxWidth: 150 }}
        src={infoSys}
        alt="Info System"
      />
    </Fragment>,
  ],
  tagLine: (
    <Fragment>
      Be a part of the course created to train the nation's{' '}
      <span className="text-black">future Responsible AI leaders!</span>
    </Fragment>
  ),
}
