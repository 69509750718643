import { Fragment } from "react";
import background from "assets/img/home/k-12-research-opportunity.jpg";

export const programBenefits = {
  background: background,
  title: (
    <Fragment>
      Program{" "}
      <span className="text-animated">
        <span>Benefits</span>
      </span>
    </Fragment>
  ),
  keypoints: [
    "Increase college competitiveness",
    "Develop transferable STEM research abilities",
    "Create a research project for college or internship portfolios",
    "Build tangible computer science skills",
    "Explore real world applications of data science research",
    "Build relationships with researchers at top universities",

  ],
  desc: ["Whether students are looking to learn more about how data science is applicable to their own interests, ethics in research, or get a head start on research before college, they will all gain knowledge and experience rarely offered  to high school students."],
};
