import { Fragment } from 'react'
import bg from 'assets/img/research/university-columbia.jpg'

import uom from 'assets/img/common/univerty-of-maryland-words.png'
import equitech from 'assets/img/common/equitech.png'
import google from 'assets/img/common/google.png'
import uci from 'assets/img/common/uci.png'
import disney from 'assets/img/common/disney.png'
import { ColumbiaUniversity } from 'component/Icons/ColumbiaUniversity'

export const banner = {
  bg: bg,
  heading: (
    <Fragment>
      <span className="d-block mb-5 ds-research-title">
        National High School Research Program
      </span>
      {/* <span className="d-block mb-10 nhsrp-title-small">with mentors from</span> */}
      {/* <span className="d-block mb-5 nhsrp-title-small">in collaboration with</span> */}
      {/* <ColumbiaUniversity height={80} /> */}
      {/* <span className='nhsrp-banner-logos'>
        <img src={uom} alt="university of Maryland" className='invert-img' style={{ height: 70 }} />
        <img src={equitech} style={{ height: 50 }} alt="Equitech" />
      </span> */}
    </Fragment>
  ),
  // mentorsLogo: {
  //   logos: [
  //     { img: google, alt: "Google" },
  //     { img: uom, alt: "University of Maryland" },
  //     { img: equitech, alt: "Equitech" },
  //     { svg: <ColumbiaUniversity height="80" />, alt: "University of Maryland" },
  //     { img: uci, alt: "University of California" },
  //     { img: disney, alt: "Disney" },
  //   ],
  //   text: "and many more..."
  // },
  subHeading: <Fragment>
    <span style={{ fontSize: '70%' }}>Increase your college competitiveness this summer <br /> by conducting research in: </span>
    <span className='d-block mt-5'>Quantum Computing <span className='mx-10'>|</span> Data Science <span className='mx-10'>|</span> Artificial Intelligence</span>
  </Fragment>,
  description: (
    <Fragment>
      <span className='fs-20 d-block'>Incoming 9<sup>th</sup> &mdash; 12<sup>th</sup> graders + rising college freshmen</span>
      {/* <span className='fs-20 d-block'>July 7 - August 8, 2025</span> */}
      <span className='fs-20 d-block'>Virtual 5-week Summer Research Opportunities</span>
    </Fragment>
  ),
  links: [
    {
      linkText: 'Apply',
      link: '/nhsrp-application',
      isExternal: false,

    },
  ],
}
