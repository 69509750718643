import { Link } from 'react-router-dom'
import classNames from 'classnames'

import styles from './styles.module.scss'

export const FutureAiLeaders = () => {
  return (
    <section className={styles.futureAiLeaders} id="introduction">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8">
            <div data-aos="fade-up" data-aos-duration="500">
              <h2 className={classNames(styles.headline, 'text-center')}>
                <span className="d-block">FUTURE AI LEADERS</span>
                <span className={styles.barOverline}>
                  <span className={styles.boText}>PROGRAM</span>
                </span>
              </h2>
            </div>
            <div
              className={classNames(styles.topDesc, 'text-center')}
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <p>
                The Coding School offers{' '}
                <span className="text-bold">first-of-its-kind programs</span>{' '}
                for high school students and educators to gain real-world skills
                and knowledge in Machine Learning (ML), a subfield of Artificial
                Intelligence.
              </p>
            </div>
          </div>
        </div>

        <div className="mt-30">
          <ul
            className={classNames(styles.tabsNav, 'nav')}
            id="trainTab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className={classNames(styles.tabTitle, 'nav-link active')}
                id="hss-tab"
                data-toggle="tab"
                data-target="#hss"
                type="button"
                role="tab"
                aria-controls="hss"
                aria-selected="true"
              >
                <span>High School Students</span>
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={classNames(styles.tabTitle, 'nav-link')}
                id="edu-tab"
                data-toggle="tab"
                data-target="#edu"
                type="button"
                role="tab"
                aria-controls="edu"
                aria-selected="false"
              >
                <span>High School & College Educators</span>
              </button>
            </li>
          </ul>
          <div className="tab-content" id="trainTabContent">
            <div
              className="tab-pane fade show active"
              id="hss"
              role="tabpanel"
              aria-labelledby="hss-tab"
            >
              <div className="row justify-content-center mt-30">
                <div className="col-12 col-md-6 col-xl-4">
                  <div
                    className={styles.main + ' bg-blue-gradient'}
                    data-aos="fade-left"
                    data-aos-duration="500"
                  >
                    <div className={styles.tagline}>
                      <h3>National High School AI Research Program</h3>
                    </div>
                    <div className={styles.desc}>
                      <p className="text-white">
                        Five-week summer research opportunity
                      </p>
                      <p className="text-white">
                        <small>June 23 - July 25, 2025</small>
                      </p>
                      <p className="text-white">Highlights:</p>
                      <ul className={styles.listCheck}>
                        <li>Increase college competitiveness</li>
                        <li>
                          Collaborate with mentors from academia, government &
                          industry
                        </li>
                        <li>Best practices research protocol</li>
                        <li>Develop advanced AI skills</li>
                        <li>Create research project to showcase</li>
                      </ul>
                    </div>
                    <div className={styles.buttonsArea}>
                      <Link
                        className={
                          styles.button + ' btn btn-gradient-2 text-white'
                        }
                        to="/nhsrp"
                      >
                        Learn More
                      </Link>
                      <Link
                        className={
                          styles.button + ' btn btn-gradient-2 text-white'
                        }
                        to="/nhsrp-application"
                      >
                        Apply
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-xl-4">
                  <div
                    className={styles.main + ' bg-blue-gradient'}
                    data-aos="fade-left"
                    data-aos-duration="500"
                  >
                    <div className={styles.tagline}>
                      <h3>Introduction to Artificial Intelligence Course</h3>
                    </div>
                    <div className={styles.desc}>
                      <p className="text-white">
                        A two-semester, for-credit course for high school
                        students.
                      </p>
                      <p className="text-white">
                        <small>September 2025-April 2026</small>
                      </p>
                      <p className="text-white">Highlights:</p>
                      <ul className={styles.listCheck}>
                        <li>For-credit course</li>
                        <li>Develop advanced skills in AI</li>
                        <li>Hands-on applications with ML algorithms</li>
                        <li>
                          Gain skills in computer vision, natural language{' '}
                        </li>
                        <li>processing, and deep learning</li>
                        <li>Create AI capstone project</li>
                      </ul>
                    </div>
                    <div className={styles.buttonsArea}>
                      <Link
                        className={
                          styles.button + ' btn btn-gradient-2 text-white'
                        }
                        to="/train-course"
                      >
                        Learn More
                      </Link>
                      <a
                        className={
                          styles.button + ' btn btn-gradient-2 text-white'
                        }
                        href="https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Interest Form
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-xl-4">
                  <div
                    className={styles.main + ' bg-blue-gradient'}
                    data-aos="fade-left"
                    data-aos-duration="500"
                  >
                    <div className={styles.tagline}>
                      <h3>Foundations of Data Science & Machine Learning</h3>
                    </div>
                    <div className={styles.desc}>
                      <p className="text-white">
                        A one-semester, for-credit course for high school
                        students.
                      </p>
                      <p className="text-white">
                        <small>September - December 2025</small>
                      </p>
                      <p className="text-white">Highlights:</p>
                      <ul className={styles.listCheck}>
                        <li>Develop foundational data science skills </li>
                        <li>Create visualizations & ML models</li>
                        <li>Learn Responsible AI</li>
                        <li>Industry expert Q&As</li>
                        <li>Gain skills in Python</li>
                        <li>Options to continue on to Semester II</li>
                      </ul>
                    </div>
                    <div className={styles.buttonsArea}>
                      <Link
                        className={
                          styles.button + ' btn btn-gradient-2 text-white'
                        }
                        to="/train-semester-course"
                      >
                        Learn More
                      </Link>
                      <a
                        className={
                          styles.button + ' btn btn-gradient-2 text-white'
                        }
                        href="https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Interest Form
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="edu"
              role="tabpanel"
              aria-labelledby="edu-tab"
            >
              <div className="row justify-content-center">
                <div className="col-12 col-md-6 col-xl-4">
                  <h5 className={styles.cardTitle + ' mt-30'}>
                    High School Educators
                  </h5>
                  <div
                    className={styles.main + ' bg-blue-gradient'}
                    data-aos="fade-left"
                    data-aos-duration="600"
                  >
                    <div className={styles.tagline}>
                      <h3>Summer Intensive PD for Educators</h3>
                    </div>
                    <div className={styles.desc}>
                      <p className="text-white">
                        A professional development (PD) course for U.S. high
                        school educators.
                      </p>
                      <p className="text-white">
                        <small>FREE PD - July 21 - 25, 2025</small>
                      </p>
                      <p className="text-white">Highlights:</p>
                      <ul className={styles.listCheck}>
                        <li>Bring ML into your classroom</li>
                        <li>Teaching support</li>
                        <li>CEU opportunity</li>
                        <li>FREE - sponsored by Infosys</li>
                        <li>
                          Create an account & register for Intro to AI Bootcamp
                          by clicking below
                        </li>
                      </ul>
                    </div>
                    <div className={styles.buttonsArea}>
                      <Link
                        className={
                          styles.button + ' btn btn-gradient-2 text-white'
                        }
                        to="/train-2-weeks-pd"
                      >
                        Learn More
                      </Link>
                      <a
                        className={
                          styles.button + ' btn btn-gradient-2 text-white'
                        }
                        href="https://pathfinders.onwingspan.com/web/en/login?ref=%2Fpage%2Fhome"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Register
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-xl-4">
                  <h5 className={styles.cardTitle + ' mt-30'}>
                    2 & 4-year College Faculty
                  </h5>
                  <div
                    className={styles.main + ' bg-blue-gradient'}
                    data-aos="fade-left"
                    data-aos-duration="600"
                  >
                    <div className={styles.tagline}>
                      <h3>AI Bootcamp for College Faculty</h3>
                    </div>
                    <div className={styles.desc}>
                      <p className="text-white mb-5">
                        Two-week bootcamps for community college, HBCU & MSI
                        faculty. Next bootcamp:
                      </p>
                      <p className="text-white">
                        {/* <small>September 29 <span className='mx-10'>&mdash;</span> October 12, 2024</small> */}
                        <small>Next bootcamp coming soon!</small>
                      </p>
                      <p className="text-white">Highlights:</p>
                      <ul className={styles.listCheck}>
                        <li>Introduction to AI/ML</li>
                        <li>Sponsored by AWS</li>
                        <li>Stipend provided</li>
                        <li>AWS Machine Learning University</li>
                      </ul>
                    </div>
                    <div className={styles.buttonsArea}>
                      <Link
                        className={classNames(
                          styles.button,
                          'btn btn-gradient-2 text-white'
                        )}
                        to="/train-aws-pd"
                      >
                        Learn More
                      </Link>
                      <a
                        className={
                          styles.button + ' btn btn-gradient-2 text-white'
                        }
                        href="https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Interest Form
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p
          className={classNames(
            'text-dark text-center mb-0',
            styles.bottomDesc
          )}
          data-aos="fade-up"
          data-aos-duration="700"
        >
          To ensure <span className="text-bold">accessibility</span>, all
          programming is virtual, meaning students and educators from anywhere
          in the United States can participate.
        </p>
      </div>
    </section>
  )
}
