import { Fade } from 'react-awesome-reveal'
import classNames from 'classnames'

import about from 'assets/img/about/about.png'

import styles from './styles.module.scss'

export const AboutTCS = () => {
  return (
    <div className={styles.aboutTcs}>
      <div className="container position-relative">
        <Fade top>
          <img
            className={styles.imgHeaderRight + ' position-absolute'}
            style={{
              top: '-11%',
              right: '-4%',
              width: '55px',
            }}
            src={about}
            alt="About"
          />
        </Fade>
        <Fade left>
          <div className={classNames(styles.textDetail, 'shadowedBox')}>
            <h2 className={styles.oneLiner}>
              Just because computer science education is available does not mean
              it is accessible.
              <span className="highlight-text d-block">
                We’re changing that.
              </span>
            </h2>
            <p className={styles.content}>
              The Coding School (TCS) is a 501(c)(3)international nonprofit
              focused on skill-building and workforce development in
              technologies that are going to change the world over the next
              decade, such as Quantum Computing and Artificial Intelligence.
              With a commitment to accessible, supportive computer science
              education, we offer first-of-their-kind pathways and programs for
              individuals at every stage of their STEM journey, including: K-12
              students and educators, community college and university students
              and faculty, and members of the workforce. Since 2014, TCS has
              trained over 50,000 individual in partnership with leading
              companies and universities including MIT, Stanford, Google, AWS,
              IBM and the Whitehouse.
            </p>
          </div>
        </Fade>
      </div>
    </div>
  )
}
