import classNames from "classnames";

import { Card } from "./components/Card";
import { Testimonial } from "./components/Testimonial";

import styles from "./styles.module.scss";

export const EmergingTechPrograms = (props) => {
  const { data, invertedQuotes } = props;
  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className={styles.emergingTechPrograms}>
      <div className={classNames(styles.containerBig, "container")}>
        {data?.header ? (
          <header>
            <h2>{data?.header?.headerTitle}</h2>
            <p>{data?.header?.headerDesc}</p>
          </header>
        ) : (
          <></>
        )}

        {data?.sectionsButtons ? (
          <div className={classNames(styles.programsList, "row")}>
            {data?.sectionsButtons?.map((item, idx) => {
              return (
                <div className="col-12 col-md-6 col-lg-3" key={idx}>
                  <button
                    className={styles.btnScroll}
                    onClick={() => handleClickScroll(item?.sectionId)}
                  >
                    <span>{item?.sectionName}</span>
                  </button>
                </div>
              );
            })}
          </div>
        ) : (
          <></>
        )}

        {data?.sectionsDetail?.map((item, idx) => {
          return (
            <section className={classNames(styles.sectionWrap, { [styles.columnReverse]: invertedQuotes })}>
              {item?.testimonials &&
                (
                  <Testimonial
                    background={item?.testimonials?.background}
                    testimony={item?.testimonials?.testimony}
                    testifier={item?.testimonials?.testifier}
                  />
                )}
              {item?.sectionData ? (
                <section
                  className={styles.section}
                  id={item?.sectionData?.sectionId}
                  key={idx}
                >
                  <div
                    className={classNames(styles.cardWrap, "bg-blue-gradient")}
                  >
                    <h3 className={styles.sectionTitle}>
                      {item?.sectionData?.sectionHeading}
                    </h3>
                    <p className={styles.sectionDescription}>
                      {item?.sectionData?.sectionDescription}
                    </p>
                    <div className="row justify-content-center">
                      {item?.sectionData?.cards?.map((card, cardIdx) => {
                        return (
                          <Card
                            horizontal={card?.isHorizontal}
                            cardClassName={card?.cardClass}
                            cardImg={card?.cardImg}
                            title={card?.cardTitle}
                            subTitle={card?.cardSubtitle}
                            keypoints={card?.keyPoints}
                            desc={card?.desc}
                            button={card?.button}
                            key={cardIdx}
                            modalData={card?.modalData}
                            dataTarget={card?.dataTarget}
                          />
                        );
                      })}
                    </div>
                  </div>
                </section>
              ) : (
                <></>
              )}
            </section>
          );
        })}
      </div>
    </div>
  );
};
