import { Fragment } from "react";
import img from "assets/img/research/nhsrp-program-overview.jpg";
import ai from "assets/img/research/artificial-intelligence.png";
import qc from "assets/img/research/quantum-computing.png";
import ds from "assets/img/research/data-science.png";

export const programOverview = {
  title: (
    <Fragment>
      Program{" "}
      <span className="text-animated">
        <span>Overview</span>
      </span>
    </Fragment>
  ),
  subTitle: "Increase college competitiveness by being one of the first high school students in the world to conduct research in Quantum Computing, Artificial Intelligence & Big Data.",
  desc: (
    <Fragment>
      The National High School Research Program offers an unprecedented opportunity for students to develop tangible technical skills in cutting-edge tech fields that are changing the world - AI, Quantum Computing and Big Data - which have applications across every industry from medicine to finance, defense, entertainment, and more.
      <br />
      <br />
      Over five weeks, students will transform from having zero knowledge to a full skill set of technical skills and will receive training and mentorships in conducting research. Working closely with local and national mentors from government, industry, or academia, students will develop their own research projects using real-world datasets and tech applications, culminating in projects that can be showcased in their college applications and presented at the TCS Summer Research Symposium.
    </Fragment>
  ),
  img: {
    src: img,
    alt: "Program Overview",
  },
  fullWidthContent:
    <Fragment>
      <div className="mt-20 bg-blue-gradient rounded p-15">
        <p className="text-center text-white fs-20">Students may apply to conduct research in</p>
        <div className="row">
          <div className="col-12 col-md-4 text-center mb-30 mb-md-0">
            <img className="invert-img" src={qc} alt="Quantum Computing" style={{ height: 50 }} />
            <p className="text-white mb-0 mt-15 fs-18">Quantum Computing</p>
          </div>
          <div className="col-12 col-md-4 text-center mb-30 mb-md-0">
            <img className="invert-img" src={ds} alt="Data Science" style={{ height: 50 }} />
            <p className="text-white mb-0 mt-15 fs-18">Data Science</p>
          </div>
          <div className="col-12 col-md-4 text-center">
            <img className="invert-img" src={ai} alt="Artificial Intelligence" style={{ height: 50 }} />
            <p className="text-white mb-0 mt-15 fs-18">Artificial Intelligence</p>
          </div>
        </div>
      </div>
    </Fragment>
};
